import {
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Progress,
} from "@chakra-ui/react";
import useCallCenter from "../../../call-center/hooks/useCallCenter";
import useInviteToCallModal from "../../../call-center/hooks/useInviteToCallModal";
import useInviteToCallMutation from "../../../call-center/hooks/useInviteToCallMutation";
import DialerTabs, { DialerTabsContext } from "../DialerTabs";

const inviteToCallDialerTabsContext: DialerTabsContext = {
  hideAgencyMembers: false,
  hideCaregivers: false,
  hidePatients: false,
  hidePhoneNumber: false,
  hidePhonebook: false,
};

export default function InviteToCallModal() {
  const modal = useInviteToCallModal();
  const invite = useInviteToCallMutation({ onSuccess: modal.onClose });
  const callCenter = useCallCenter();

  return (
    <Modal isOpen={modal.isOpen} size="2xl" onClose={modal.onClose}>
      <ModalOverlay zIndex={10001} />
      <ModalContent containerProps={{ zIndex: 10001 }}>
        <ModalCloseButton />
        <ModalBody minH="500px">
          <Progress isIndeterminate hidden={!modal.isOpen || !invite.isPending} size="xs" />

          <ModalHeader textAlign="center">
            <Heading>Add To Call</Heading>
          </ModalHeader>

          {(() => {
            if (callCenter.state.currentCall === null) return;
            const callId = callCenter.state.currentCall.id;
            return (
              <DialerTabs
                context={inviteToCallDialerTabsContext}
                isCalling={invite.isPending}
                onClickAgencyMember={(id) =>
                  invite.mutate({ invitee: { type: "AgencyMember", id }, callId })
                }
                onClickDial={(phonenumber, type) =>
                  invite.mutate({ callId, invitee: { type, phonenumber } })
                }
              />
            );
          })()}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
