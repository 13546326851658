import {
  Box,
  Button,
  Center,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  Spinner,
} from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useRouter } from "@uirouter/react";
import { z } from "zod";
import { auth } from "../../../../core/auth";
import ErrorPage from "../../../../shared/components/ErrorPage";
import Link from "../../../../shared/components/Link";
import QueryResolver from "../../../../shared/components/QueryResolver";
import useApi from "../../../../shared/hooks/useApi";
import useAuthData from "../../../../shared/hooks/useAuthInfo";
import usePathParams from "../../../../shared/hooks/usePathParams";
import { queryKeys } from "../../../../shared/query-keys";
import {
  PatientId,
  WorkflowTaskClusterId,
  WorkflowTaskInstanceId,
} from "../../../../shared/schema/schema";
import { fmap } from "../../../../shared/utils";
import { zPathParamId } from "../../../../shared/utils/zod";
import IntakePatientProfileRoute from "../../../patientIntake/components/IntakePatientProfileRoute";
import HumanTaskForm from "../../components/HumanTaskForm/HumanTaskForm";
import ClusteredTaskButton from "../WorkflowTaskViewer/components/ClusteredTaskButton";
import ClusteredTasks from "../WorkflowTaskViewer/components/ClusteredTasks";

const zPathParams = z.object({
  id: zPathParamId(WorkflowTaskInstanceId).optional(),
  intakePatient: z.number().optional(),
});

export default function WorkflowTaskDrawer() {
  const { id, intakePatient } = usePathParams(zPathParams);
  const { stateService } = useRouter();

  return (
    <>
      <Drawer
        blockScrollOnMount={false}
        isOpen={id !== undefined && intakePatient === undefined}
        placement="right"
        trapFocus={false}
        onClose={() => stateService.go("app.workflow_tasks")}
      >
        <DrawerOverlay />
        {id !== undefined && <InnerDrawer id={id} />}
      </Drawer>

      <IntakePatientProfileRoute
        patientId={fmap(intakePatient, PatientId.parse) ?? undefined}
        onClose={() => stateService.go("app.workflow_tasks.task", { id })}
      />
    </>
  );
}

function InnerDrawer(props: { id: WorkflowTaskInstanceId }) {
  const queryClient = useQueryClient();
  const { api, queries } = useApi();
  const { stateService } = useRouter();
  const { agencyMember } = useAuthData();

  const query = useQuery({
    queryKey: queryKeys.workflowTask.item(props.id),
    select: (data) => data.item,
    queryFn: () =>
      api.get("./workflow_tasks/:workflowTaskInstanceId", {
        path: {
          workflowTaskInstanceId: props.id,
        },
      }),
  });

  const assignToMe = useMutation({
    mutationFn: () =>
      api.post("./workflow_tasks/:workflowTaskInstanceId/assign", {
        path: {
          workflowTaskInstanceId: props.id,
        },
        body: {
          assignee: agencyMember.id,
        },
      }),
    onSuccess: () => {
      query.refetch();
      queryClient.invalidateQueries(queries.workflow.tasks());
    },
  });

  const unassign = useMutation({
    mutationFn: () =>
      api.post("./workflow_tasks/:workflowTaskInstanceId/unassign", {
        path: {
          workflowTaskInstanceId: props.id,
        },
      }),
    onSuccess: () => {
      query.refetch();
      queryClient.invalidateQueries(queries.workflow.tasks());
    },
  });

  const handleSubmit = () =>
    stateService.go("app.workflow_tasks", undefined, { location: "replace" });

  if (query.isPending) {
    return (
      <DrawerContent minW="75%">
        <Center color="gray.500" flex={1} flexDirection="column" gap={8}>
          <Spinner size="xl" />
        </Center>
      </DrawerContent>
    );
  }

  if (query.isError) {
    return (
      <DrawerContent minW="75%">
        <ErrorPage error={new Error(`${query.error}`)} resetErrorBoundary={query.refetch} />;
      </DrawerContent>
    );
  }

  const { data: task } = query;

  const handleToggleAssignment = () => {
    if (task.assignee === null) {
      assignToMe.mutate();
    } else {
      unassign.mutate();
    }
  };

  const shouldShowAssignmentButton =
    (task.assignee === null && auth.isAllowedTo("assign_workflow_task")) ||
    (task.assignee === agencyMember.id && auth.isAllowedTo("unassign_workflow_task"));

  const handleOpenAngularModal = () => {
    stateService.go("app.workflow_tasks");
  };

  const handleCloseAngularModal = () => {
    setTimeout(() => {
      stateService.go("app.workflow_tasks.task", { id: props.id });
    }, 0);
  };

  return (
    <DrawerContent minW="75%">
      <DrawerHeader borderBottomWidth="1px">
        <Flex gap={8} justifyContent="space-between" wrap="wrap">
          <Heading size="lg">{task.meta.name}</Heading>
          {shouldShowAssignmentButton && (
            <Button
              isLoading={assignToMe.isPending || unassign.isPending}
              onClick={handleToggleAssignment}
            >
              {task.assignee === null ? "Assign to me" : "Unassign"}
            </Button>
          )}
        </Flex>
      </DrawerHeader>

      {task.cluster !== null && (
        <>
          <RelatedClusteredTasks cluster={task.cluster} selectedWorkflowTaskInstanceId={props.id} />
          <Divider />
        </>
      )}

      <DrawerBody pb={16} px={0}>
        <Box height="full">
          <HumanTaskForm
            task={task}
            onCloseAngularModal={handleCloseAngularModal}
            onOpenAngularModal={handleOpenAngularModal}
            onSubmit={handleSubmit}
          />
        </Box>
      </DrawerBody>
    </DrawerContent>
  );
}

function RelatedClusteredTasks(props: {
  selectedWorkflowTaskInstanceId: WorkflowTaskInstanceId;
  cluster: {
    id: WorkflowTaskClusterId;
    value: string;
  };
}) {
  const { queries } = useApi();

  return (
    <QueryResolver
      options={queries.workflow.clusteredTasks({ cluster: props.cluster })}
      onError="Failed to load cluster"
      onLoading={<ClusteredTasks.Skeleton />}
      onSuccess={({ data }) => (
        <ClusteredTasks.Root>
          <ClusteredTasks.Heading
            cluster={{
              id: props.cluster.id,
              value: props.cluster.value,
              name: data.cluster.name,
            }}
          />
          {data.tasks.map((task) => (
            <ClusteredTaskButton.UI
              key={task.id}
              as={Link}
              colorScheme={task.id === props.selectedWorkflowTaskInstanceId ? "blue" : undefined}
              params={{ id: task.id }}
              to="app.workflow_tasks.task"
            >
              {task.meta.name}
            </ClusteredTaskButton.UI>
          ))}
        </ClusteredTasks.Root>
      )}
    />
  );
}
