import { LocalDate } from "@js-joda/core";

//! @ngInject
export function sentToPhysicianDocumentModalCtrl ($scope, $uibModalInstance, DatabaseApi, toaster, apiErrors, dateUtils) {
      $scope.form = {
        sentDate: dateUtils.ymdStringToDate(LocalDate.now().toJSON()),
      };

      $scope.submit = () => {
        if ($scope.form.sentDate === null) {
          toaster.pop("error", "Error", "Please select a date");
        }

        const body = {
          sentDate: dateUtils.dateToLocalDate($scope.form.sentDate),
        };

        const url =
          "agencies/:agencyId/agency_members/:agencyMemberId/patient_documents/:patientDocumentId/mark_as_sent_to_physician"
            .replace(":agencyId", $scope.agencyId)
            .replace(":agencyMemberId", $scope.agencyMemberId)
            .replace(":patientDocumentId", $scope.$resolve.document.id);

        $scope.isLoading = true;
        DatabaseApi.put(url, body)
          .then((response) => {
            $scope.$resolve.onSuccess?.(response.data.document);
            toaster.pop("success", "Success", "Marked as sent to physician");
            $uibModalInstance.close();
          })
          .catch((error) => {
            console.error(error);
            toaster.pop(
              "error",
              "Something went wrong",
              apiErrors.format(error, "Failed to sign physician document")
            );
          })
          .finally(() => ($scope.isLoading = false));
      };

      $scope.close = () => {
        $uibModalInstance.close();
      };
    }
