import { LocalDate, LocalDateTime } from "@js-joda/core";
import moment from "moment";

export const patientCalendar = {
  templateUrl: "admin/views/patient-calendar.html",
  bindings: {
    patientId: "<",
    patient: "<",
    calendarInit: "<",
    onClickNote: "&",
    onClickNewVisit: "&",
    onClickNewTask: "&",
    onClickEditTask: "&",
    onClickCancelTask: "&",
    onClickStopTaskBroadcast: "&",
    onClickScheduleTask: "&",
    onClickEditItems: "&",
    onClickDeleteItems: "&",
    onClickMissItems: "&",
    onClickRemoveItems: "&",
    onClickStopBroadcastVisits: "&",
    onChangePatientVacations: "&",
    onCreatePatientVacations: "&",
    onClickStopBroadcastFlexibleVisit: "&",
    onClickOpenBroadcastFlexibleVisitModal: "&",
    showDeletedVisits: '<',
    extraColumns: '<',
    editShiftsParams: '<'
  },
  //! @ngInject
  controller: function (
    $rootScope,
    $scope,
    $filter,
    DatabaseApi,
    CalendarItemType,
    toaster,
    entityNewVisitModalService,
    visitInstanceService,
    $timeout,
    generalUtils,
    tasksService,
    mfModal
  ) {
    const vm = this;
    vm.initializeMap = DatabaseApi.entitiesInitializeMap();
    vm.getInitialStateEntity = () => {
      return {
        data: [],
        isLoading: false,
        error: undefined,
      };
    };

    vm.patientId = $scope.patientId;

    vm.calendarType = "PATIENT";
    vm.caregiversMap = DatabaseApi.caregivers();
    vm.serviceCodesMap = DatabaseApi.serviceCodes();
    vm.multipleDaysSelection = false;
    vm.isAllowItemsCheck = !$rootScope.isNewVisitSideModalOpen;

    $scope.$on("got_caregivers_data", () => {
      vm.initializeMap = DatabaseApi.entitiesInitializeMap();
      vm.caregiversMap = DatabaseApi.caregivers();
      vm.fetchData();
    });
    $scope.$on("got_service_codes", () => {
      vm.initializeMap = DatabaseApi.entitiesInitializeMap();
      vm.serviceCodesMap = DatabaseApi.serviceCodes();
      vm.fetchData();
    });

    vm.state = {
      items: vm.getInitialStateEntity(),
      events: vm.getInitialStateEntity(),
      tasks: vm.getInitialStateEntity(),
      dates: undefined,
      startDayOfWeek: $rootScope.visitSettings.calendarStartDayOfTheWeek
        ? parseInt($rootScope.visitSettings.calendarStartDayOfTheWeek)
        : 0,
    };

    vm.editShiftsOptions = entityNewVisitModalService.editShiftsOptions;
    vm.editShiftsParams = $scope.$ctrl.editShiftsParams;
    vm.isForwardShiftsDatePickerOpen = false;
    vm.editUntilMinDate = undefined;
    vm.editUntilMaxDate = undefined;
    vm.isOnboardingAgency = $rootScope.isOnboardingAgency;

    vm.actions = [
      {
        label: "New Visit",
        invoke: (selection) => vm.handleClickNewVisit(selection),
        permissionKey: 'create_new_patient_visit',
        isHidden: (selection) => vm.isSelectionContainingVacation(selection)
      },
      {
        label: "New Task",
        invoke: (selection) => vm.handleClickNewTask(selection),
        permissionKey: 'edit_new_patient_task',
        isHidden: (selection) => vm.isSelectionContainingVacation(selection)
      },
      {
        label: "New Note",
        invoke: (selection) => vm.handleClickNewNote(selection),
        permissionKey: 'edit_new_patient_note'
      },
      {
        label: "Set On Vacation",
        invoke: (selection) => vm.handleClickVacation(selection),
        permissionKey: 'edit_new_patient_vacation',
        isHidden: (selection) => vm.isSelectionContainingVacation(selection)
      },
      {
        label: "Delete Vacation",
        invoke: (selection) => vm.handleClickVacation(selection),
        permissionKey: 'edit_new_patient_vacation',
        isHidden: (selection) => !vm.isSelectionContainingVacation(selection)
      },
      // {
      //   label: "Edit Visits",
      //   invoke: (selection) => vm.handleEdit(selection),
      // },
    ];

    vm.isSelectionContainingVacation = ({ val }) => {
      return val.day.items.find(item => item.type === CalendarItemType.PATIENT_VACATION) !== undefined;
    };

    vm.fetchData = (force) => fetchDataForVm($scope, vm, DatabaseApi, $rootScope.agencyId, $rootScope.agencyMemberId, force);

    vm.loadTasks = () => loadTasks(vm, DatabaseApi, $rootScope.agencyId, $rootScope.agencyMemberId);

    vm.loadFlexibleVisitBroadcasts = () => loadFlexibleVisitBroadcasts(vm, DatabaseApi, $rootScope.agencyId, $rootScope.agencyMemberId);

    vm.setItemsData = () => {
      const filteredVisitInstances = calculateOverlappingVisits(vm.filterVisitInstances());

      vm.state.items = {
        data: [
          ...(vm?.itemsAndEventsData?.data?.patientVacations ?? []).map(
            vm.mapPatientVacationToMfCalendarDayItem
          ),
          ...filteredVisitInstances
            .sort(vm.sortVisitInstances)
            .map(vm.mapVisitInstanceToMfCalendarItem),
        ],
        isLoading: false
      };
    }

    $rootScope.$on("visit_changed", (event, data) => {
      if (!data || !data.visitData || data.visitData.patientId != vm.patientId) return;
      const v = data.visitData;
      vm.itemsAndEventsData.data.visitInstances.forEach(vi => {
        if (vi.visit.id == v.id && (vi.acceptedBy && vi.acceptedBy.length || 0) != (v.acceptedBy && v.acceptedBy.length || 0)) {
          vm.loadItemsAndEvents();
          return;
        }
      });
    });

    vm.handleChangeView = ({ from, to }) => {
      if (
        vm.state.dates &&
        vm.state.dates.from.equals(from) &&
        vm.state.dates.to.equals(to)
      ) {
        return; // do nothing
      }
      vm.state.dates = { from, to };

      vm.fetchData();
    };

    vm.handleCreate = (calendarLocalDate = undefined) => {
      let calendarDate = undefined;
      if (calendarLocalDate !== undefined) {
        calendarDate = new Date(calendarLocalDate);
        const calendarLocalDateEpochDay = calendarLocalDate.toEpochDay();
        if (vm.state.items.data.find(e => e.type === CalendarItemType.PATIENT_VACATION && e.date.toEpochDay() === calendarLocalDateEpochDay)) {
          return toaster.pop("error", "You can not create a visit on vacation");
        }
      }

      const newVisitData = {
        patientId: vm.patient.id,
        patientAddress: vm.patient.address,
        patientContracts: vm.patient.contracts,
        patientMainLanguage: vm.patient.mainLanguage,
        patientSecondaryLanguage: vm.patient.secondaryLanguage,
        patientPhoneNumbers: vm.patient.phoneNumbers,
        patientVacations: vm.patient.vacations.filter(v => !v.removedAt),
        patientAuthorizations: vm.patient.authorizations,
        calendarDate: calendarDate
      };

      entityNewVisitModalService.setNewVisitData(newVisitData);
      vm.onClickNewVisit()();
    };

    $rootScope.$on("calendar_new_visit", () => {
      console.log("calendar_new_visit");
      vm.onResetSelection()
      vm.isAllowItemsCheck = false;
    });

    $rootScope.$on("calendar_edit_visit", () => {
      console.log("calendar_edit_visit");
      vm.updateItemsActionsDisables();
    });

    vm.mapBulkVisit = (visit) => {
      const visitStartDate = moment(visit.start_time);
      const caregiverId = visit.caregiver_id ? vm.caregiversMap[visit.caregiver_id] : null;

      const type = getMfCalendarItemTypeByVisitInstance({
        caregiverId: caregiverId,
        missedVisit: visit.missed_visit,
        removedAt: visit.removed_at,
        visitBroadcast: visit.visit_broadcast_id,
        isTask: visit.is_task,
        visitStartTime: visit.start_time
      });

      const visitBroadcast = {
        // todo requests,
        startDateTime: visit.start_time,
        type: vm.editShiftsParams.type,
        visitBroadcastId: visit.visit_broadcast_id
      };

      return {
        key: `visit-${visit.visit_instance_id}`,
        visitInstanceId: visit.visit_instance_id,
        day: visitStartDate.format("dddd"),
        date: visitStartDate.format("MM.DD.YYYY"),
        startTime: visitStartDate,
        endTime: moment(visit.end_time),
        type: type,
        visitBatchId: visit.visit_batch_id,
        visitBatchType: "PERMANENT",
        visitBroadcast: visitBroadcast,
        // todo visitBatchMinStartDate,
        // todo visitBatchMaxEndDate,
        caregiver: caregiverId,
        isBilled: visit.is_billed,
        isPaid: visit.is_paid,
        isOnPayrollDraft: visit.draft_paid_seconds > 0,
      }
    };

    vm.mapCalendarItemToVisit = (item) => {
      const visitStartDate = moment(item.date.toJSON());
      return {
        key: item.key,
        visitInstanceId: item.payload.visitInstanceId,
        day: visitStartDate.format("dddd"),
        date: visitStartDate.format("MM.DD.YYYY"),
        startTime: moment(item.payload.startTime),
        endTime: moment(item.payload.endTime),
        type: item.type,
        visitBatchId: item.payload.visitBatchId,
        visitBatchType: item.payload.visitBatchType,
        visitBroadcast: item.payload.visitBroadcast,
        visitBatchMinStartDate: item.payload.visitBatchMinStartDate,
        visitBatchMaxEndDate: item.payload.visitBatchMaxEndDate,
        caregiver: item.payload.caregiverId ? vm.caregiversMap[item.payload.caregiverId] : null,
        isBilled: item.payload.billedLabel !== null,
        isPaid: item.payload.paidLabel !== null,
        isOnPayrollDraft: item.payload.payrollDraftLabel !== null
      }
    }

    vm.mapCalendarItemToVacation = (item) => {
      return {
        key: item.key,
        id: item.payload.id,
        date: item.payload.date,
        patientId: item.payload.patientId,
        removedAt: item.payload.removedAt,
        type: item.type
      }
    }

    vm.handleEdit = () => {
      const isUntilDateChecked = vm.editShiftsParams.type === "UNTIL_DATE";

      entityNewVisitModalService.setEditVisitData({
        patientId: vm.patient.id,
        patientAddress: vm.patient.address,
        patientContracts: vm.patient.contracts,
        patientMainLanguage: vm.patient.mainLanguage,
        patientSecondaryLanguage: vm.patient.secondaryLanguage,
        patientPhoneNumbers: vm.patient.phoneNumbers,
        patientVacations: vm.patient.vacations.filter(v => !v.removedAt),
        patientAuthorizations: vm.patient.authorizations,
        targetElementId: 'scroll-calendar',
        editShiftsParams: vm.editShiftsParams.type,
        editShiftsParamsUntilDate: isUntilDateChecked ? vm.editShiftsParams.untilDate : undefined,
      });

      vm.onClickEditItems()();
    }

    vm.handleDelete = () => {
      $scope.$ctrl.onClickDeleteItems()('scroll-calendar');
    };

    vm.handleRemove = () => {
      $scope.$ctrl.onClickRemoveItems()('scroll-calendar');
    };

    vm.handleMiss = () => {
      $scope.$ctrl.onClickMissItems()('scroll-calendar');
    };

    vm.handleStopBroadcast = () => {
      entityNewVisitModalService.setStopBroadcastVisitData({
        editShiftsParams: vm.editShiftsParams.type,
      });
      $scope.$ctrl.onClickStopBroadcastVisits()('scroll-calendar');
    };

    vm.itemsActions = !$rootScope.isPermittedByKey("edit_visit_instance_general_info") ? [] : [
      {
        text: "New Visit",
        variant: "primary",
        callback: vm.handleCreate,
        isDisabled: false,
        permissionKey: 'create_new_patient_visit'
      },
      {
        text: "Edit",
        variant: "primary",
        callback: vm.handleEdit,
        isDisabled: true,
        permissionKey: 'edit_patient_visit'
      },
      {
        text: "Stop Broadcast",
        variant: "danger",
        callback: vm.handleStopBroadcast,
        isDisabled: true,
        title: 'Only broadcasted visits should be selected!',
        permissionKey: 'edit_patient_visit'
      }
    ];

    if (!$rootScope.isOnboardingAgency) {
      vm.itemsActions.push(
      {
        text: "Miss",
        variant: "danger",
        callback: vm.handleMiss,
        isDisabled: true,
        permissionKey: 'edit_patient_visit'
      },
      {
        text: "Delete",
        variant: "danger",
        callback: vm.handleDelete,
        isDisabled: true,
        permissionKey: 'delete_patient_visit'
      },
      )
    } else {
      vm.itemsActions.push(
        {
          text: "Remove",
          variant: "danger",
          callback: vm.handleRemove,
          isDisabled: true,
          permissionKey: 'delete_patient_visit'
        }
        )
    }

    vm.handleCheckSingleItem = (item) => {
      if (!item.key) {
        return;
      }

      if (item.type === CalendarItemType.PATIENT_VACATION) {
        let newSelectedVacations = angular.copy($scope.selectedVacations);
        const indexOfVacation = newSelectedVacations.findIndex(vacation => vacation.key === item.key);
        if (indexOfVacation === -1) {
          newSelectedVacations.push(vm.mapCalendarItemToVacation(item));
        } else {
          newSelectedVacations.splice(indexOfVacation, 1);
        }
        vm.setSelectedItems({ vacations: newSelectedVacations });
      } else {
        let newSelectedVisits = angular.copy($scope.selectedVisits);
        const indexOfVisit = newSelectedVisits.findIndex(visit => visit.key === item.key);
        if (indexOfVisit === -1) {
          newSelectedVisits.push(vm.mapCalendarItemToVisit(item));
        } else {
          newSelectedVisits.splice(indexOfVisit, 1);
        }
        vm.setSelectedItems({ visits: newSelectedVisits });

        vm.editShiftsParams.type = 'CURRENT_SHIFTS';
      }

      vm.updateItemsActionsDisables();

      if ($rootScope.isNewVisitSideModalOpen === true) {
        vm.handleEdit();
      }
    };

    vm.updateItemsActionsDisables = () => {
      console.log('updateItemsActionsDisables');
      const allSelectedVisits = entityNewVisitModalService.selectedItems.visits;

      const selectedBroadcasts = allSelectedVisits.filter(
        item => item.type === CalendarItemType.BROADCASTED_VISIT
      );

      const selectedVacations = $scope.selectedVacations.filter(
        item => item.type === CalendarItemType.PATIENT_VACATION
      );

      const isOnlyVisitsSelection = (allSelectedVisits.length > 0 && selectedVacations.length === 0);
      const isOnlyVacationsSelection = (selectedVacations.length > 0 && allSelectedVisits.length === 0);
      const isOnlyBroadcastsSelection = (selectedBroadcasts.length > 0 && selectedVacations.length === 0);

      vm.itemsActions.forEach(action => {
        switch (action.text) {
          case "New Visit":
            action.isDisabled = vm.isAllowItemsCheck === false;
            break;
          case "Edit":
            action.isDisabled = vm.isAllowItemsCheck === false || !(
              isOnlyVisitsSelection || isOnlyVacationsSelection
            );
            break;
          case "Delete":
            action.isDisabled = vm.isAllowItemsCheck === false || !(
              isOnlyVisitsSelection || isOnlyVacationsSelection
            );
            break;
          case "Miss":
            action.isDisabled = vm.isAllowItemsCheck === false || !(
              isOnlyVisitsSelection
            );
            break;
          case "Stop Broadcast":
            action.isDisabled = vm.isAllowItemsCheck === false || !(
              isOnlyBroadcastsSelection
            );
            break;
          case "Remove":
            action.isDisabled = vm.isAllowItemsCheck === false || !(
              isOnlyVisitsSelection || isOnlyVacationsSelection
            );
            break;
          default:
            break;
        }
      });
    };

    vm.handleClickNewVisit = ({ day }) => {
      vm.handleCreate(day.date);
    };

    vm.handleClickNewTask = ({ day }) => {
      vm.onClickNewTask()(day.date);
    };

    vm.handleClickNewNote = (selection) => {
      let calendarDate;

      if (vm.multipleDaysSelection === true) {
        calendarDate = selection.first.date
      } else {
        calendarDate = selection.day.date
      }

      const newScope = $scope.$new();
      newScope.note = {
        patientId: vm.patient.id,
        calendarDate: calendarDate
      };

      $rootScope.openNewCalendarNoteModal(newScope).then((result) => {
        if (result === "OK") {
          vm.fetchData();
        }
      });
    };

    vm.handleClickVacation = (selection) => {
      let startTime;
      let endTime;
      if (vm.multipleDaysSelection === true) {
        startTime = selection.first.date < selection.last.date ? selection.first.date : selection.last.date;
        endTime = selection.first.date > selection.last.date ? selection.first.date : selection.last.date;
      } else {
        startTime = selection.day.date;
        endTime = selection.day.date;
      }

      const startEpochDay = startTime.toEpochDay();
      const endEpochDay = endTime.toEpochDay();

      const findConflictItem = vm.state.items.data.find(item => {
        const itemEpochDay = item.date.toEpochDay();
        return (
          itemEpochDay >= startEpochDay &&
          itemEpochDay <= endEpochDay &&
          item.type === CalendarItemType.PATIENT_VACATION
        );
      });

      if (findConflictItem !== undefined) {
        vm.setSelectedItems({ visits: [], vacations: [vm.mapCalendarItemToVacation(findConflictItem)] });
        $scope.$ctrl.onClickDeleteItems()('scroll-calendar');
        return;
      }

      $scope.$ctrl.onCreatePatientVacations()(selection.day.date).then((res) => {
        if (res === "VACATION_CREATED") {
          vm.fetchData();
          generalUtils.scrollToElement('scroll-calendar');
        }
      });
    };

    vm.changeShiftParams = (type) => {
      console.log("changeShiftParams", type);
      vm.editShiftsParams.type = type;
      vm.updateSelectedVisits();
      $rootScope.$broadcast('patient_calendar_update_visit_selection_type', type);
    }

    vm.untilDateSelected = () => {
      console.log("untilDateSelected", type);
      vm.updateSelectedVisits();
    }

    vm.updateSelectedVisits = () => {
      const editVisitsData = entityNewVisitModalService.editVisitsData;
      if (editVisitsData && editVisitsData.editShiftsParams !== vm.editShiftsParams.type) {
        editVisitsData.editShiftsParams = vm.editShiftsParams.type;
      }

      entityNewVisitModalService.fetchAllVisits(
        $scope.$ctrl.patient.id,
        vm.editShiftsParams,
        $scope.selectedVisits
      )
        .then(({ data }) => {
          editVisitsData.visitInstancesDetails = data.visits;
          entityNewVisitModalService.setEditVisitData(editVisitsData);
          const newVisits = data.visits.map(vm.mapBulkVisit).filter(v => !!v);
          vm.setSelectedItems({ visits: newVisits });
        });
    }

    vm.onResetSelection = () => {
      vm.editShiftsParams = { type: 'CURRENT_SHIFTS', untilDate: undefined };
      vm.setSelectedItems({ visits: [], vacations: [] });
      vm.state.items.data.forEach(item => item.checked = false);
      vm.updateItemsActionsDisables();
    }

    vm.handleClickItem = (item) => {
      switch (item.type) {
        case CalendarItemType.BROADCASTED_VISIT:
        case CalendarItemType.BROADCASTED_IN_THE_PAST:
          $rootScope.openVisitBroadcastModalById(item.payload.visitBroadcast.visitBroadcastId);
          return;
        case CalendarItemType.UNSTAFFED_VISIT:
        case CalendarItemType.DELETED_VISIT:
        case CalendarItemType.MISSED_VISIT:
        case CalendarItemType.ASSIGNED_VISIT:
        case CalendarItemType.ASSIGNED_TASK:
        case CalendarItemType.PAID_TIME_OFF:
          $rootScope.openVisitInstanceModal(item.payload.visitInstanceId, item.payload.patientId);
          return;
        default:
          console.log(
            `There's no case for handling item of type "${item.type}"`
          );
      }
    };

    const patientCalendarFilterByMethods = {
      visitInstanceIsDeleted: (visit) => visit.removedAt === null
    }

    vm.filterVisitInstances = () => {
      if (!vm.itemsAndEventsData.data) return [];
      const filters = [];

      if ($scope.$ctrl.showDeletedVisits === false) {
        filters.push(visitInstance => patientCalendarFilterByMethods.visitInstanceIsDeleted(visitInstance.visit));
      }

      let filtereVisitInstances = vm.itemsAndEventsData.data.visitInstances;
      if (filters.length > 0) {
        filtereVisitInstances = filtereVisitInstances.filter(function (visit) {
          let isFiltered = true;
          for (let idx = 0; isFiltered && idx < filters.length; idx++) {
            isFiltered = isFiltered && filters[idx](visit);
          }
          return isFiltered;
        });
      }

      return filtereVisitInstances;
    };

    vm.sortVisitInstances = (a, b) => {
      if (
        (a.isVisitDateOnDayBefore || b.isVisitDateOnDayBefore) &&
        a.isVisitDateOnDayBefore !== b.isVisitDateOnDayBefore
      ) {
        return a.isVisitDateOnDayBefore ? 1 : -1;
      }

      return LocalDateTime.parse(a.visit.startTime).compareTo(
        LocalDateTime.parse(b.visit.startTime)
      );
    };

    vm.mapVisitInstanceToMfCalendarItem = (visitInstance) => {
      const type = getMfCalendarItemTypeByVisitInstance({
        caregiverId: visitInstance.visit.caregiverId,
        missedVisit: visitInstance.visit.missedVisit,
        removedAt: visitInstance.visit.removedAt,
        visitBroadcast: visitInstance.visitBroadcast,
        isTask: visitInstance.isTask,
        visitStartTime: visitInstance.visit.startTime
      });

      let requests = 0;
      if (visitInstance.visitBroadcast) {
        requests = visitInstance.visitBroadcast.requests;
      }

      const getPtoLabel = (status) => {
        switch (status) {
          case "APPROVED":
            return "Approved";
          case "PENDING":
            return "Pending";
          case "DECLINED":
            return "Declined";
          case false:
            return null;
        }
      };

      const broadcastStatus = visitInstanceService.getVisitInstanceBroadcastStatus(visitInstance);
      const visitInstanceIssues = visitInstanceService.getVisitInstanceIssues(visitInstance, vm.issuesSettings);

      const key = `visit-${visitInstance.visit.id}`;
      const selectedVisitsKeys = $scope.selectedVisits.map(x => x.key);
      return {
        key: key,
        checked: selectedVisitsKeys.includes(key),
        date: LocalDate.parse(visitInstance.visitDate),
        type: type,
        options: {
          allowNavigateToCaregiver: true,
        },
        payload: {
          isOnWeeklyTemplate: visitInstance.isOnWeeklyTemplate,
          broadcastStatus: broadcastStatus,
          requests: requests,
          visitInstanceId: visitInstance.visit.id,
          billingStatus: getMfCalendarItemBillingStatusByVisitInstance(
            visitInstance
          ),
          billedLabel:
            visitInstance.billedSeconds === 0
              ? null
              : $filter("duration")([0, visitInstance.billedSeconds * 1000]),
          billedSeconds: visitInstance.billedSeconds,
          paidLabel:
            visitInstance.paidSeconds === 0
              ? (visitInstance.isPaid
                ? "0h" : null) :
              $filter("duration")([0, visitInstance.paidSeconds * 1000]),
          paidSeconds: visitInstance.paidSeconds,
          payrollDraftLabel:
            visitInstance.draftPaidSeconds === 0
              ? null
              : $filter("duration")([0, visitInstance.draftPaidSeconds * 1000]),
          caregiverId: visitInstance.visit.caregiverId,
          caregiverName:
            visitInstance.visit.caregiverId === null
              ? null
              : vm.fmap(
                vm.caregiversMap[visitInstance.visit.caregiverId],
                vm.getFullName
              ),
          patientId: visitInstance.visit.patientId,
          patientName: visitInstance.visit.patientName,
          startTime: visitInstance.visit.startTime,
          endTime: visitInstance.visit.endTime,
          clockinTime: visitInstance.visit.clockinTime,
          clockoutTime: visitInstance.visit.clockoutTime,
          billOnPreviousDay: visitInstance.visit.isVisitDateOnDayBefore,
          ptoStatusLabel: getPtoLabel(visitInstance.ptoStatus),
          visitBatchId: visitInstance.visit.visitBatchId,
          visitBatchType: visitInstance.visitBatchType,
          visitBroadcast: visitInstance.visitBroadcast,
          visitBatchMinStartDate: visitInstance.visitBatchMinStartDate,
          visitBatchMaxEndDate: visitInstance.visitBatchMaxEndDate,
          issues: visitInstanceIssues,
          isClockInOverlap: visitInstance.isClockInOverlap,
          isClockOutOverlap: visitInstance.isClockOutOverlap,
          parentSplitVisitInstanceId: visitInstance.parentSplitVisitInstanceId,
          childSplitVisitInstanceId: visitInstance.childSplitVisitInstanceId,
          pendingConfirmationUntil: visitInstance.pendingConfirmationUntil
        },
      };
    };

    vm.mapPatientVacationToMfCalendarDayEvent = (vacation) => {
      return {
        type: CalendarItemType.PATIENT_VACATION,
        date: LocalDate.parse(vacation.date),
        payload: vacation,
      };
    };

    vm.mapPatientVacationToMfCalendarDayItem = (vacation) => {
      const key = `vacation-${vacation.id}`;
      const selectedVacationsKeys = $scope.selectedVacations.map(x => x.key);

      return {
        key: key,
        checked: selectedVacationsKeys.includes(key),
        date: LocalDate.parse(vacation.date),
        type: CalendarItemType.PATIENT_VACATION,
        options: {},
        payload: vacation,
      };
    };

    function getMfCalendarItemTypeByVisitInstance({
      caregiverId,
      missedVisit,
      removedAt,
      visitBroadcast,
      isTask,
      visitStartTime
    }) {
      // DELETED_VISIT
      if (removedAt) {
        return CalendarItemType.DELETED_VISIT;
      }

      // MISSED_VISIT
      if (missedVisit) {
        return CalendarItemType.MISSED_VISIT;
      }

      // BROADCASTED_VISIT
      if (visitBroadcast !== null) {

        // BROADCASTED IN THE PAST - This is a visit that is a part of a broadcast, but it's start date had passed.
        if (visitBroadcast.startDateTime) {
          const broadcastStartTime = LocalDateTime.parse(visitBroadcast.startDateTime);
          const visitStartTimeAsLocalDate = LocalDateTime.parse(visitStartTime);

          if (visitStartTimeAsLocalDate.isBefore(broadcastStartTime)) {
            return CalendarItemType.BROADCASTED_IN_THE_PAST;
          }
        }

        return CalendarItemType.BROADCASTED_VISIT;
      }

      // UNSTAFFED_VISIT
      if (caregiverId === null) {
        return CalendarItemType.UNSTAFFED_VISIT;
      }

      if (isTask) {
        return CalendarItemType.ASSIGNED_TASK;
      }

      // ASSIGNED_VISIT
      return CalendarItemType.ASSIGNED_VISIT;
    }

    function getMfCalendarItemBillingStatusByVisitInstance({
      missingAuth,
      issue_authorization_over_allocation,
      issue_schedule_auth_allocation_conflict,
      issue_missing_authorization_hours,
      issue_no_matching_authorization,
      issue_invalid_authorization_assignment
    }) {
      if (missingAuth || issue_invalid_authorization_assignment || issue_no_matching_authorization) {
        return {
          message: "No Authorization"
        };
      }

      if (issue_missing_authorization_hours) {
        return {
          message: "Auth Insufficient Hours",
          tooltip: issue_missing_authorization_hours
        };
      }

      if (issue_authorization_over_allocation) {
        return {
          message: "Auth Over Allocation",
          tooltip: issue_authorization_over_allocation
        };
      }

      if (issue_schedule_auth_allocation_conflict) {
        return {
          message: "Auth Conflict",
          tooltip: issue_schedule_auth_allocation_conflict
        };
      }

      return null;
    }

    const calculateOverlappingVisits = (visitInstances) => {
      visitInstances.sort((visitA, visitB) => {
        const visitAClockInTime = visitA.visit.clockinTime !== null ? LocalDateTime.parse(visitA.visit.clockinTime) : LocalDateTime.MAX;
        const visitBClockInTime = visitB.visit.clockinTime !== null ? LocalDateTime.parse(visitB.visit.clockinTime) : LocalDateTime.MAX;

        return visitAClockInTime.compareTo(visitBClockInTime);
      });

      for (let i = 1; i < visitInstances.length; i++) {
        const areOverlapping = visitInstances[i - 1].visit.clockoutTime > visitInstances[i].visit.clockinTime;
        if (
            areOverlapping && (
              isOverlappingPermittedByServiceCode(visitInstances[i - 1]) ||
              isOverlappingPermittedByServiceCode(visitInstances[i])
            )
        ) {
          visitInstances[i].isClockInOverlap = true;
          visitInstances[i - 1].isClockOutOverlap = true;
        }
      }

      return visitInstances;
    }

    const isOverlappingPermittedByServiceCode = (visitInstance) => {
      const serviceCodeId = visitInstance.visit.serviceCodeId;
      if (!serviceCodeId) {
        return false;
      }

      const serviceCode = vm.serviceCodesMap.find(item => item.id === serviceCodeId);
      return serviceCode?.allowVisitOverlap ?? false;
    }

    vm.fmap = (target, predicate) => {
      if (target === null || target === undefined) {
        return target;
      }

      return predicate(target);
    };

    vm.getFullName = ({ firstName, middleName, lastName }) => {
      return `${firstName || ""} ${middleName || ""} ${lastName || ""}`.trim();
    };

    vm.handleClickEditTask = (task) => {
      if (task.taskInstanceId) {
        return $scope.$ctrl.onClickEditTask()(task);
      }

      return tasksService.getPatientTaskInstanceByVisitInstanceId(task.visitInstanceId)
        .then((data) => {
          Object.assign(task, mapPatientTasks(vm, data));
          $scope.$ctrl.onClickEditTask()(task);
        })
        .catch((e) => {
          return toaster.pop("error", "Cannot get visit instance relevant task");
        });
    };

    vm.getSelectedItems = () => {
      $scope.selectedVisits = entityNewVisitModalService.selectedItems.visits;
      $scope.selectedVacations = entityNewVisitModalService.selectedItems.vacations;
      console.log("getSelectedItems", $scope.selectedVisits);

      const lastVisit = $scope.selectedVisits[$scope.selectedVisits.length - 1];
      if (lastVisit) {
        $timeout(function () {
          vm.editUntilMinDate = moment(lastVisit.startTime);
        });
      }
    };

    vm.setSelectedItems = ({ visits, vacations }) => {
      entityNewVisitModalService.setSelectedItems({ visits, vacations });
    };

    vm.getSelectedItems();

    vm.observerCallback = () => {
      vm.getSelectedItems();
      console.log("observerCallback");
      vm.updateItemsActionsDisables();
    };

    entityNewVisitModalService.registerObserverCallback(
      "visits",
      "patientCalendar",
      vm.observerCallback
    );
    entityNewVisitModalService.registerObserverCallback(
      "vacations",
      "patientCalendar",
      vm.observerCallback
    );

    vm.$onDestroy = () => {
      entityNewVisitModalService.unregisterObserverCallback("visits", "patientCalendar");
      entityNewVisitModalService.unregisterObserverCallback("vacations", "patientCalendar");
    };

    $rootScope.$on("refresh_visits", () => {
      vm.fetchData(true);
      console.log("refresh_visits");
      vm.onResetSelection();
    });

    $rootScope.$on("close_new_visit_modal", () => {
      vm.isAllowItemsCheck = true;
      console.log("close_new_visit_modal");
      vm.updateItemsActionsDisables();
    });

    $rootScope.$on("patient_task_saved", () => {
      vm.state.tasks.isLoading = true;
      vm.loadTasks();
    });

    $rootScope.$on("flexible_visit_broadcast", () => {
      vm.state.flexibleVisitBroadcasts.isLoading = true;
      vm.loadFlexibleVisitBroadcasts();
    });

    vm.$onChanges = (changedData) => {
      const previousValue = changedData.showDeletedVisits && changedData.showDeletedVisits.previousValue;
      if (!changedData || vm.state.items.isLoading || !vm.patient.id ||
        (typeof previousValue === 'object' && Object.keys(previousValue).length === 0)) {
        return;
      }
      vm.setItemsData();
    }

    vm.$onInit = () => {
      vm.state.editTask = vm.onClickEditTask();
      vm.state.cancelTask = vm.onClickCancelTask();
      vm.state.stopTaskBroadcast = vm.onClickStopTaskBroadcast();
      vm.state.scheduleTask = vm.onClickScheduleTask();
      vm.state.stopFlexibleVisitBroadcast = vm.onClickStopBroadcastFlexibleVisit();
      vm.state.openBroadcastFlexibleVisitModal = vm.onClickOpenBroadcastFlexibleVisitModal();
      $scope.prologue = { ngInclude: 'admin/views/calendar-indicator-bars.html', state: vm.state };

      if (
        vm.calendarInit?.getItemsPromise
        && vm.calendarInit?.promiseFrom
        && vm.calendarInit?.promiseTo
      ) {
        vm.itemsAndEventsData = {
          from: vm.calendarInit.promiseFrom,
          to: vm.calendarInit.promiseTo,
          data: null,
          promise: vm.calendarInit.getItemsPromise,
          isLoading: true
        };
        followUpOnItemsAndEventsData(vm.itemsAndEventsData.promise, $scope, vm, DatabaseApi, vm.itemsAndEventsData.from, vm.itemsAndEventsData.to);
      }
    };
  },
};

function fetchDataForVm(scope, vm, dbapi, agencyId, agencyMemberId, force) {
  if (!vm.initializeMap["serviceCodes"] || !vm.initializeMap["caregivers"]) {
    return;
  }

  loadItemsAndEventsIfNeeded(scope, vm, dbapi, agencyId, agencyMemberId, force);
  loadTasks(vm, dbapi, agencyId, agencyMemberId);
  loadFlexibleVisitBroadcasts(vm, dbapi, agencyId, agencyMemberId);
};

function fetchCalendarItems(vm, dbapi, agencyId, agencyMemberId, fromDate, toDate) {
  let url =
    "agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/visit_instances_billing"
      .replace(":agencyId", agencyId)
      .replace(":agencyMemberId", agencyMemberId)
      .replace(":patientId", vm.patientId);

  if (fromDate && toDate) {
    url += "?from=:from&to=:to"
      .replace(":from", fromDate)
      .replace(":to", toDate);
  }

  return dbapi.get(url);
}

async function loadItemsAndEventsIfNeeded(scope, vm, dbapi, agencyId, agencyMemberId, force) {
  const fromDate = vm.state.dates ? vm.state.dates.from.minusDays(1) : null;
  const toDate = vm.state.dates ? vm.state.dates.to.plusDays(1) : null;

  // Range inside, already loading. No action required.
  if (
    !force &&
    vmDatesWithinStateAndLoading(fromDate, toDate, vm.itemsAndEventsData, false, true)
  ) {
    return;
  }

  // Range inside, already loaded. Perhaps data unrelated to fetch changed. Redo handle.
  if (
    !force &&
    vmDatesWithinStateAndLoading(fromDate, toDate, vm.itemsAndEventsData, false, false)
  ) {
    handleItemsAndEventsData(scope, vm);
    return;
  }

  vm.itemsAndEventsData = {
    from: fromDate,
    to: toDate,
    promise: null,
    isLoading: true
  };

  const fetchCalendarItemsPromise = fetchCalendarItems(vm, dbapi, agencyId, agencyMemberId, fromDate, toDate);

  followUpOnItemsAndEventsData(fetchCalendarItemsPromise, scope, vm, dbapi, fromDate, toDate);
};

function followUpOnItemsAndEventsData(getUrlPromise, scope, vm, dbapi, fromDate, toDate) {
  vm.state.items = { ...vm.getInitialStateEntity(), isLoading: true };
  vm.state.events = { ...vm.getInitialStateEntity(), isLoading: true };

  getUrlPromise
    .then((result) => {
      return getAgencyBillingIssues(dbapi).then((issuesSettings) => {
        vm.issuesSettings = issuesSettings;
        return result;
      });
    })
    .then(({ data }) => {
      if (vmDatesWithinStateAndLoading(fromDate, toDate, vm.itemsAndEventsData, true)) {
        vm.itemsAndEventsData = {
          from: fromDate,
          to: toDate,
          data,
          promise: null,
          isLoading: false
        };
      
        handleItemsAndEventsData(scope, vm);
      }
    })
    .catch((e) => {
      if (vmDatesWithinStateAndLoading(fromDate, toDate, vm.itemsAndEventsData, true)) {
        console.error(e);
        vm.state.items.error = e.message;
        vm.state.items.isLoading = false;
        vm.state.events.isLoading = false;
      }
    });
}

async function getAgencyBillingIssues(dbapi) {
  return await dbapi.agencyBillingIssues();
}

function handleItemsAndEventsData(scope, vm) {
  vm.setItemsData();

  vm.state.events = {
    data: [
      ...mapPatientNotesToMfCalendarDayEvent({
        vm,
        notes: vm.itemsAndEventsData.data.notes,
        onClickFactory: (date) => () =>
          handleClickPatientNoteEvent(vm, date),
      }),
    ],
    isLoading: false,
  };

  // The before adding vm.patient.vacations !== undefined, it was always defined and thus this was always called. Now it might be called after. Need to see if that's okay.
  if (vm.patient.vacations !== undefined && !areArraysIdsMatching(vm.patient.vacations, vm.itemsAndEventsData.data.patientVacations)) {
    const vacationsNonUnique = vm.patient.vacations.concat(vm.itemsAndEventsData.data.patientVacations);
    const newPatientVacationsIds = [...new Set(vacationsNonUnique.map(vacation => vacation.id))];
    const newPatientVacations = newPatientVacationsIds.map(vacationId =>
      vacationsNonUnique.find(vacation => vacation.id === vacationId)
    );
    scope.$ctrl.onChangePatientVacations()(newPatientVacations);
  }
}

function vmDatesWithinStateAndLoading(from, to, state, equals, isLoading) {
  const validFromDate = 
    // Both undefined.
    (!state?.from && !from)
    // Both defined and within range.
    || (state?.from !== undefined && from !== undefined && (equals ? state.from.equals(from) : !state.from.isAfter(from))); 

  const validToDate =
    // Both undefined.
    (!state?.to && !toDate)
    // Both defined and within range.
    || (state?.to !== undefined && to !== undefined && (equals ? state.to.equals(to) : !state.to.isBefore(to)));

  return (
    validFromDate
    && validToDate
    && (isLoading === undefined || state?.isLoading === isLoading)
  )
}

function areArraysIdsMatching (arrA, arrB) {
  return (
    arrA.find(a => arrB.find(b => a.id === b.id) === undefined) === undefined &&
    arrB.find(b => arrA.find(a => b.id === a.id) === undefined) === undefined
  );
};

function mapPatientNotesToMfCalendarDayEvent ({
  vm,
  notes,
  onClickFactory,
}) {
  const notesPerDayMap = new Map();
  const toReturn = [];

  for (const note of notes) {
    const totalNotesInDay = notesPerDayMap.get(note.calendarDate) || 0;

    notesPerDayMap.set(note.calendarDate, totalNotesInDay + 1);
  }

  for (const [date, totalNotes] of notesPerDayMap.entries()) {
    toReturn.push({
      type: "NOTE",
      date: LocalDate.parse(date),
      onClick: onClickFactory(date),
      payload: {
        total: totalNotes,
      },
    });
  }

  return toReturn;
};

function handleClickPatientNoteEvent (vm, date) {
  if (vm.onClickNote()) {
    vm.onClickNote()({ date });
  }
};

async function loadTasks(vm, dbapi, agencyId, agencyMemberId) {
  vm.state.tasks = { ...vm.getInitialStateEntity(), isLoading: true };
  const now = LocalDate.now();

  let url = "agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/patient_task_instances_billing"
    .replace(":agencyId", agencyId)
    .replace(":agencyMemberId", agencyMemberId)
    .replace(":patientId", vm.patientId);

  if (vm.state.dates) {
    url += "?from=:from&to=:to"
      .replace(":from", vm.isOnboardingAgency ? now.minusYears(2) :vm.state.dates.from)
      .replace(":to", vm.isOnboardingAgency ? now.plusYears(2) :vm.state.dates.to);
  }

  return dbapi.get(url)
    .then(({ data }) => {
      vm.state.tasks = {
        data: data.patientTaskInstances.map((task)=> mapPatientTasks(vm, task)),
        isLoading: false,
      };
    })
    .catch((e) => {
      console.error(e);
      vm.state.tasks.error = e.message;
      vm.state.tasks.isLoading = false;
    });
};

function mapPatientTasks(vm, taskInstance) {
  taskInstance.state = getPatientTaskState(taskInstance);
  taskInstance.startDate = moment(taskInstance.startDate, 'YYYY-MM-DD');
  taskInstance.dueDate = moment(taskInstance.dueDate, 'YYYY-MM-DD');
  taskInstance.caregiver = taskInstance.caregiverId ? vm.caregiversMap[taskInstance.caregiverId] : null;

  if (taskInstance.type === "RegularTask") {
    taskInstance.documents = taskInstance.documents.map(doc => doc.documentTypeId);
  }

  return taskInstance;
};

function getPatientTaskState(taskInstance) {
  if (taskInstance.type === "Broadcasting") {
    return "Broadcasting";
  }

  if (taskInstance.type === "FutureTask") {
    return "Future";
  }

  if (taskInstance.caregiverId) {
    return "Assigned";
  }

  return "Unstaffed";
};

async function loadFlexibleVisitBroadcasts(vm, dbapi, agencyId, agencyMemberId) {
  vm.state.flexibleVisitBroadcasts = { ...vm.getInitialStateEntity(), isLoading: true };

  let url = `agencies/${agencyId}/agency_members/${agencyMemberId}/patients/${vm.patientId}/flexible_visit_broadcast`;

  if (vm.state.dates) {
    url += `?from=${vm.state.dates.from}&to=${vm.state.dates.to}`;
  }

  return dbapi.get(url)
    .then(({ data }) => {
      vm.state.flexibleVisitBroadcasts = {
        data: data.flexibleVisitBroadcasts,
        isLoading: false,
      };
    })
    .catch((e) => {
      console.error(e);
      vm.state.flexibleVisitBroadcasts.error = e.message;
      vm.state.flexibleVisitBroadcasts.isLoading = false;
    });
};