import { AddressComponents } from "../../../public/admin/scripts/messages/address_component";
import { TicketPopupOptions } from "../modules/communication/components/CommCenterChatWrapper";
import { EntityCommunicationsProps } from "../modules/communication/components/entity-communications/EntityCommunications";
import { NewTicketPopupProps } from "../modules/communication/components/NewTicketPopup";
import { OpenSmsSenderData } from "../shared/components/ReactHost/SmsSenderListener";
import { GlobalWorkflowHint } from "../shared/hooks/useGlobalWorkflowRunner";
import {
  CaregiverId,
  NoteId,
  PatientId,
  VisitBroadcastId,
  VisitInstanceId,
} from "../shared/schema/schema";
import { SocketEvent } from "../types/socket";
import { Messages } from "./api";

enum ReactBridgeEventType {
  PAYLOAD_SET = "react-bridge-payload-set",
  DISPATCH = "react-bridge-dispatch",
}

function createReactBridge() {
  function getPayload<T extends keyof AngularJSPayload>(key: T): AngularJSPayload[T] | undefined {
    return (window as any).__ANGULARJS_PAYLOAD__?.[key];
  }

  function setPayload<T extends keyof AngularJSPayload>(key: T, payload: AngularJSPayload[T]) {
    if ((window as any).__ANGULARJS_PAYLOAD__ === undefined) {
      (window as any).__ANGULARJS_PAYLOAD__ = {};
    }

    (window as any).__ANGULARJS_PAYLOAD__[key] = payload;

    const event = new CustomEvent(ReactBridgeEventType.PAYLOAD_SET, { detail: { key, payload } });

    window.dispatchEvent(event);
  }

  function dispatch<T extends keyof AngularJSDispatch>(key: T, payload: AngularJSDispatch[T]) {
    const event = new CustomEvent(ReactBridgeEventType.DISPATCH, { detail: { key, payload } });

    window.dispatchEvent(event);
  }

  function onPayloadSet<T extends keyof AngularJSPayload>(
    key: T,
    callback: (payload: AngularJSPayload[T]) => void
  ) {
    const listener = ($event: Event) => {
      const event = $event as CustomEvent;
      if (event.type === ReactBridgeEventType.PAYLOAD_SET && event.detail.key === key) {
        callback(event.detail.payload);
      }
    };

    window.addEventListener(ReactBridgeEventType.PAYLOAD_SET, listener);

    return () => window.removeEventListener(ReactBridgeEventType.PAYLOAD_SET, listener);
  }

  function onDispatch<T extends keyof AngularJSDispatch>(
    key: T,
    callback: (payload: AngularJSDispatch[T]) => void
  ) {
    const listener = ($event: Event) => {
      const event = $event as CustomEvent;
      if (event.type === ReactBridgeEventType.DISPATCH && event.detail.key === key) {
        callback(event.detail.payload);
      }
    };

    window.addEventListener(ReactBridgeEventType.DISPATCH, listener);

    return () => window.removeEventListener(ReactBridgeEventType.DISPATCH, listener);
  }

  return {
    getPayload,
    setPayload,
    dispatch,
    onPayloadSet,
    onDispatch,
  };
}

export type EventFromReact =
  | {
      type: "navigate";
      payload:
        | { id: CaregiverId; entity: "Caregiver" }
        | { id: PatientId; entity: "Patient" }
        | { id: VisitInstanceId; entity: "VisitInstance" }
        | { id: VisitBroadcastId; entity: "VisitBroadcast" }
        | { id: string; entity: "Document" };
      redirect?: boolean;
      onClose?: () => void;
    }
  | {
      type: "customAddressChange";
      payload:
        | {
            entity: "Caregiver";
            id: CaregiverId;
            address: string;
            addressComponents: AddressComponents;
            addressGeoLocation: Messages["Caregiver"]["addressGeoLocation"];
          }
        | {
            entity: "Patient";
            id: PatientId;
            address: string;
            timezone: string;
            addressComponents: AddressComponents;
          };
    };

export interface AngularJSPayload {
  OPEN_CUSTOMIZE_ADDRESS_MODAL:
    | {
        entity: "Caregiver";
        id: CaregiverId;
      }
    | {
        entity: "Patient";
        id: PatientId;
      };
  CUSTOM_ADDRESS_CHANGE:
    | {
        entity: "Caregiver";
        id: CaregiverId;
      }
    | {
        entity: "Patient";
        id: PatientId;
      };
}

export interface AngularJSDispatch {
  SOCKET_EVENT_RECEIVED: SocketEvent;
  "COMM_CENTER:OPEN_TICKET_POPUP": TicketPopupOptions;
  "COMM_CENTER:OPEN_NEW_TICKET_POPUP": NewTicketPopupProps;
  "COMM_CENTER:OPEN_ENTITY_COMMUNICATIONS": EntityCommunicationsProps;
  "CALL_CENTER:OPEN_DIALER_POPUP": { predefinedPhoneNumber?: string };
  "CALL_CENTER:OPEN_RINGING_CALLS": undefined;
  "CALL_CENTER:CLOSE_DIALER_POPUP": undefined;
  "GLOBAL_WORKFLOW_RUNNER:OPEN": undefined;
  "GLOBAL_WORKFLOW_RUNNER:SET_HINTS": {
    hints: GlobalWorkflowHint[];
  };
  "EDIT_NOTE_MODAL:OPEN": { id: NoteId };
  OPEN_SMS_SENDER: OpenSmsSenderData;
  "CUSTOM_ADDRESS:OPEN_MODAL":
    | {
        entity: "Caregiver";
        id: CaregiverId;
      }
    | {
        entity: "Patient";
        id: PatientId;
      };
  "CUSTOM_ADDRESS:ON_CHANGE":
    | {
        entity: "Caregiver";
        id: CaregiverId;
      }
    | {
        entity: "Patient";
        id: PatientId;
      };
}

export default createReactBridge();
