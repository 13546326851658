import { PhoneIcon } from "@chakra-ui/icons";
import { Flex, FlexProps, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import Dialpad from "../../../shared/components/DialPad";
import { useSearchCaregiversQuery } from "../../caregiver/hooks/useSearchCaregiversQuery";
import { useSearchPatientsQuery } from "../../patient/hooks/useSearchPatientsQuery";
import DialerCaregiversSearch from "../../../shared/components/DialerCaregiversSearch";
import DialerPatientsSearch from "../../../shared/components/DialerPatientsSearch";
import { AgencyMemberId } from "../../../shared/schema/schema";
import DialerAgencyMembersSearch from "../../../shared/components/DialerAgencyMembersSearch";
import DialerPhonebookSearch from "../../../shared/components/DialerPhonebookSearch";
import { Messages } from "../../../core/api";

export type SearchEntity =
  | (NonNullable<ReturnType<typeof useSearchCaregiversQuery>["data"]>[number] & {
      type: "Caregiver";
    })
  | (NonNullable<ReturnType<typeof useSearchPatientsQuery>["data"]>[number] & {
      type: "Patient";
    });

export interface DialerTabsContext {
  hidePatients: boolean;
  hideCaregivers: boolean;
  hidePhoneNumber: boolean;
  hideAgencyMembers: boolean;
  hidePhonebook: boolean;
}

interface Props {
  isCalling: boolean;
  context: DialerTabsContext;
  flexProps?: FlexProps;
  predefinedPhoneNumber?: string;
  onClickDial: (
    E164number: string,
    context: Exclude<Messages["TelephonyInviteToCallInvitee"]["type"], "AgencyMember">
  ) => void;
  onClickAgencyMember: (agencyMemberId: AgencyMemberId) => void;
}

const DialerTabs = (props: Props) => {
  const {
    context: { hideAgencyMembers, hideCaregivers, hidePatients, hidePhoneNumber, hidePhonebook },
  } = props;

  const createHandleClickDial =
    (context: Exclude<Messages["TelephonyInviteToCallInvitee"]["type"], "AgencyMember">) =>
    (E164number: string) =>
      props.onClickDial(E164number, context);
  return (
    <Flex {...props.flexProps}>
      <Tabs align="center" variant="enclosed" w="full">
        <TabList gap={4}>
          {!hidePhoneNumber && <Tab>Number</Tab>}
          {!hideAgencyMembers && <Tab>Agency Members</Tab>}
          {!hidePatients && <Tab>Patients</Tab>}
          {!hideCaregivers && <Tab>Caregivers</Tab>}
          {!hidePhonebook && <Tab>Phonebook</Tab>}
        </TabList>
        <TabPanels>
          {!hidePhoneNumber && (
            <TabPanel>
              <Dialpad
                flexProps={{ w: "60%" }}
                state={{
                  type: "PhoneNumberDialer",
                  dialButtonConent: "Call",
                  dialButtonLeftIcon: <PhoneIcon />,
                  isCalling: props.isCalling,
                  predefinedPhoneNumber: props.predefinedPhoneNumber,
                  onDialNumber: createHandleClickDial("NotIdentified"),
                }}
              />
            </TabPanel>
          )}
          {!hideAgencyMembers && (
            <TabPanel>
              <DialerAgencyMembersSearch
                isLoading={props.isCalling}
                onClickAgencyMember={props.onClickAgencyMember}
              />
            </TabPanel>
          )}
          {!hidePatients && (
            <TabPanel
              as={Flex}
              flexDirection="column"
              gap={4}
              hidden={props.context.hidePatients}
              textAlign="start"
            >
              <DialerPatientsSearch
                isLoading={props.isCalling}
                onClickDial={createHandleClickDial("Patient")}
              />
            </TabPanel>
          )}
          {!hideCaregivers && (
            <TabPanel
              as={Flex}
              flexDirection="column"
              gap={4}
              hidden={props.context.hideCaregivers}
              textAlign="start"
            >
              <DialerCaregiversSearch
                isLoading={props.isCalling}
                onClickDial={createHandleClickDial("Caregiver")}
              />
            </TabPanel>
          )}
          {!hidePhonebook && (
            <TabPanel
              as={Flex}
              flexDirection="column"
              gap={4}
              hidden={props.context.hidePhonebook}
              textAlign="start"
            >
              <DialerPhonebookSearch
                isLoading={props.isCalling}
                onClickDial={createHandleClickDial("PhonebookContact")}
              />
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </Flex>
  );
};

export default DialerTabs;
