import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Flex,
  FlexProps,
  Input,
  Portal,
  Spinner,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { Duration, LocalDateTime } from "@js-joda/core";
import { UseQueryResult } from "@tanstack/react-query";
import React, { useState } from "react";
import { auth } from "../../../core/auth";
import { useStorageState } from "../../hooks/useStorageState";
import { fmap } from "../../utils";
import { durationFormatter } from "../../utils/duration-formatter";
import Select from "../Select";
import {
  MessageTemplate,
  PhoneNumber,
  QueriedEntity,
  SendPortalLinkSmsBody,
  SendSmsBody,
} from "./sms-sender.types";
import { replaceMessageTemplatePlaceholders } from "./sms-sender.utils";
import { CommCenterTeamId } from "../../schema/schema";
import CommCenterTeamSelect from "../CommCenterTeamSelect";

type Props = {
  entityQuery: UseQueryResult<QueriedEntity>;
  messageTemplates?: MessageTemplate[];
  predefinedPhoneNumber?: string;
  predefinedMessageTemplate?: MessageTemplate;
  flexProps?: FlexProps;
  onSend: (body: SendSmsBody | SendPortalLinkSmsBody) => void;
  isSending: boolean;
  onClose: () => void;
};

function SmsSender(props: Props) {
  return (
    <Portal>
      <Box
        bg="white"
        borderTopRadius="2xl"
        bottom={39}
        boxShadow="0 0 16px -2px rgba(0,0,0,0.25)"
        display="flex"
        flexDirection="column"
        gap={2}
        id="sms-sender"
        padding={10}
        position="fixed"
        right={55}
        transition="all 250ms ease"
        w="600px"
        zIndex={10000}
      >
        <>
          <CloseIcon cursor="pointer" onClick={props.onClose} />
          {(() => {
            switch (props.entityQuery.status) {
              case "success": {
                if (props.entityQuery.data.phoneNumbers.length === 0) {
                  return (
                    <Center>
                      <Text>Entity has no phone numbers available. Can&rsquo;t send SMS.</Text>
                    </Center>
                  );
                }
                return (
                  <SmsSenderContent
                    entity={props.entityQuery.data}
                    flexProps={props.flexProps}
                    isSending={props.isSending}
                    messageTemplates={props.messageTemplates ?? []}
                    predefinedMessageTemplate={props.predefinedMessageTemplate}
                    predefinedPhoneNumber={props.predefinedPhoneNumber}
                    onSend={props.onSend}
                  />
                );
              }
              case "error":
                return (
                  <Center m={40}>
                    <Text fontSize={16}>Error while loading sms sender</Text>
                  </Center>
                );
              case "pending":
                return (
                  <Center m={40}>
                    <Spinner size="xl" />
                  </Center>
                );
            }
          })()}
        </>
      </Box>
    </Portal>
  );
}

interface SmsSenderContentProps {
  entity: QueriedEntity;
  messageTemplates: MessageTemplate[];
  predefinedPhoneNumber?: string;
  predefinedMessageTemplate?: MessageTemplate;
  flexProps?: FlexProps;
  onSend: (body: SendSmsBody | SendPortalLinkSmsBody) => void;
  isSending: boolean;
}

const SmsSenderContent = (props: SmsSenderContentProps) => {
  const predefinedPhoneNumber = props.entity.phoneNumbers.find(
    (x) => x.value === props.predefinedPhoneNumber
  );
  const formatMessgeTemplate = (message: string) => {
    return replaceMessageTemplatePlaceholders(message, {
      firstName: props.entity.firstName,
      agencyMember: auth.getAuthOrFail().data.agencyMember,
    });
  };

  const [message, setMessage, cacheOptions] = useStorageState({
    key: React.useMemo(() => ["sms-sender", "draft", props.entity], [props.entity]),
    initialState: () => formatMessgeTemplate(props.predefinedMessageTemplate?.value ?? ""),
    debounce: 1000,
    storage: sessionStorage,
  });

  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState<PhoneNumber>(
    predefinedPhoneNumber ?? props.entity.phoneNumbers[0]
  );
  const [messageTemplate, setMessageTemplate] = useState<MessageTemplate | undefined>(
    props.predefinedMessageTemplate
  );
  const [team, setTeam] = useState<CommCenterTeamId | undefined>(undefined);

  const isLinkMessageTemplate = messageTemplate !== undefined && "redirectUrl" in messageTemplate;

  const handleSend = () => {
    const body: SendSmsBody | SendPortalLinkSmsBody = {
      phoneNumber: selectedPhoneNumber.value,
      message: message,
      teamId: team,
      ...(isLinkMessageTemplate && { redirectUrl: messageTemplate.redirectUrl }),
    };

    props.onSend(body);

    cacheOptions.removeCache();
  };

  const handleSelectMessageTemplate = (selected: string | undefined) => {
    const foundMessageTemplate = props.messageTemplates.find((option) => option.value === selected);
    if (foundMessageTemplate !== undefined) {
      setMessage(formatMessgeTemplate(foundMessageTemplate.value));
      setMessageTemplate(foundMessageTemplate);
    } else {
      setMessageTemplate(undefined);
      setMessage("");
    }
  };

  const handleChangePhoneNumber = (e?: string) => {
    if (e !== undefined) {
      const selected = props.entity.phoneNumbers.find((option) => option.value === e);
      if (selected !== undefined) {
        setSelectedPhoneNumber(selected);
      }
    }
  };

  const handleChangeTeam = (teamId?: CommCenterTeamId) => {
    setTeam(teamId);
  };

  const formattedCachedAt = fmap(
    cacheOptions.cachedAt,
    (x) => durationFormatter.relative(Duration.between(LocalDateTime.now(), x)).formatted
  );

  return (
    <Flex direction="column" gap={4} {...props.flexProps}>
      <Flex alignItems="center" gap={2}>
        <Text fontSize="lg">{"To: "}</Text>
        {props.entity.phoneNumbers.length === 0 || props.entity.phoneNumbers.length === 1 ? (
          <Input disabled value={selectedPhoneNumber?.label} />
        ) : (
          <Select
            allowUnselect={false}
            buttonProps={{ w: "100%" }}
            label="Phone number"
            multiple={false}
            options={props.entity.phoneNumbers}
            value={selectedPhoneNumber?.value ?? ""}
            width="450px"
            onChange={handleChangePhoneNumber}
          />
        )}
      </Flex>

      <Flex alignItems="center" gap={2}>
        <Text fontSize="lg">{"Team: "}</Text>
        <CommCenterTeamSelect
          buttonProps={{ w: "100%" }}
          multiple={false}
          value={team ?? null}
          onChange={handleChangeTeam}
        />
      </Flex>

      {props.messageTemplates.length > 0 && (
        <Flex alignItems="center" gap={2}>
          <Text fontSize="lg">{"Template: "}</Text>
          <Select
            allowUnselect={true}
            buttonProps={{ w: "100%" }}
            closeOnUnselect={true}
            label="Message template"
            maxH="350px"
            multiple={false}
            options={props.messageTemplates}
            searchable={true}
            value={messageTemplate?.value ?? null}
            width="450px"
            onChange={handleSelectMessageTemplate}
          />
        </Flex>
      )}
      <Flex direction="column" gap={1} minH={60}>
        <Textarea
          flex={1}
          placeholder="Text message"
          resize="none"
          rows={5}
          size="md"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        {formattedCachedAt !== null && (
          <Flex align="center" gap={1} justify="flex-end" p={2}>
            <CheckIcon color="green.500" fontSize="xs" />
            <Text fontSize="sm">{`Last saved ${formattedCachedAt}`}.</Text>
            <Button size="sm" variant="link" onClick={cacheOptions.reset}>
              Clear draft
            </Button>
          </Flex>
        )}
      </Flex>
      <Button
        colorScheme="blue"
        disabled={props.isSending}
        isLoading={props.isSending}
        onClick={handleSend}
      >
        Send
      </Button>
      <Text>
        * Sending an SMS will create a Comm Center ticket where the communication will be handled
        via SMS.
      </Text>
      {isLinkMessageTemplate && (
        <Text>* Upon sending the message, the link will be generated and sent alongside it.</Text>
      )}
    </Flex>
  );
};

export default SmsSender;
