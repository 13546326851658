import { EventFromReact } from "@medflyt/webapp-react/src/core/react-bridge";
import ReactGlobalHost from "@medflyt/webapp-react/src/shared/components/ReactHost/ReactGlobalHost";
import ReactHost from "@medflyt/webapp-react/src/shared/components/ReactHost/ReactHost";
import { renderReactComponent } from "@medflyt/webapp-react/src/shared/utils/render-react-component";
import { withAppOnly, withRouter } from "@medflyt/webapp-react/src/utils";
import angular from "angular";
import { DatabaseApiService } from "public/admin/scripts/services/db";
import React from "react";

//! @ngInject
export class ReactAdapterService {
  private hasInitReactHost = false;

  constructor(
    public $rootScope: angular.IRootScopeService,
    public Storage: any,
    public $state: ng.ui.IStateService,
    public $timeout: ng.ITimeoutService,
    public DatabaseApi: DatabaseApiService
  ) {
    this.listenForReactEvents();
  }

  init() {
    if (!this.hasInitReactHost) {
      renderReactComponent({
        $element: [document.body],
        component: withAppOnly(withRouter(React.memo(ReactHost))),
        props: {},
      });

      renderReactComponent({
        $element: [document.body],
        component: withAppOnly(withRouter(React.memo(ReactGlobalHost))),
        props: {},
      });

      this.hasInitReactHost = true;
    }
  }

  listenForReactEvents() {
    window.addEventListener("from-webapp-react", ($event) => {
      const event = ($event as CustomEvent).detail as EventFromReact;
      if (event.type === "navigate") {
        switch (event.payload.entity) {
          case "Caregiver":
            return this.$rootScope.openCaregiverModal(
              event.payload.id as any,
              undefined,
              undefined,
              undefined,
              {
                redirect: event.redirect,
                onClose: event.onClose,
              }
            );
          case "Patient":
            return this.$rootScope.openPatientModal(event.payload.id as any, undefined, {
              redirect: event.redirect,
              onClose: event.onClose,
            });
          case "VisitInstance":
            return this.$rootScope.openVisitInstanceModal(event.payload.id as any, undefined, {
              redirect: event.redirect,
              onClose: event.onClose,
            });
          case "VisitBroadcast":
            return this.$rootScope.openVisitBroadcastModalById(event.payload.id as any, {
              onClose: event.onClose,
            });
          case "Document":
            return this.$rootScope.openDocumentPreviewModalById(event.payload.id as any);
        }
      } else if (event.type === "customAddressChange") {
        switch (event.payload.entity) {
          case "Caregiver":
            this.$rootScope.receiveCaregiverCustomAddressChange(
              event.payload.id as any,
              event.payload.address,
              event.payload.addressComponents,
              event.payload.addressGeoLocation
            );
            break;
          case "Patient":
            this.$rootScope.receivePatientCustomAddressChange(
              event.payload.id as any,
              event.payload.address,
              event.payload.timezone,
              event.payload.addressComponents
            );
            break;
        }
      }
    });
  }
}
