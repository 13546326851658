import { Box, Input, Spinner, Text } from "@chakra-ui/react";
import { useState } from "react";
import useAgencyMembersQuery from "../../modules/agencyMembers/hooks/useAgencyMembersQuery";
import { AgencyMemberId } from "../schema/schema";
import EntityCard from "./EntityCard";

interface Props {
  isLoading: boolean;
  onClickAgencyMember: (agencyMemberId: AgencyMemberId) => void;
}

const DialerAgencyMembersSearch = (props: Props) => {
  const [agencyMemberSearch, setAgencyMemberSearch] = useState("");
  const agencyMembers = useAgencyMembersQuery({ searchTerm: agencyMemberSearch });

  return (
    <Box>
      <Input
        mb={2}
        type="text"
        value={agencyMemberSearch}
        onChange={(e) => setAgencyMemberSearch(e.currentTarget.value)}
      />
      {(() => {
        switch (agencyMembers.status) {
          case "pending":
            return <Spinner />;
          case "error":
            return <Text>Error loading agency members</Text>;
          case "success":
            return agencyMembers.data.map((agencyMember) => (
              <EntityCard
                key={agencyMember.id}
                boxProps={{
                  opacity: props.isLoading ? 0.5 : 1,
                  onClick: () => {
                    if (!props.isLoading) {
                      props.onClickAgencyMember(agencyMember.id);
                    }
                  },
                  cursor: "pointer",
                  mb: 2,
                  borderBottom: "1px",
                  borderColor: "gray.100",
                }}
                entity={{
                  type: "Agency Member",
                  fullName: `${agencyMember.firstName} ${agencyMember.lastName}`,
                  ...agencyMember,
                }}
              />
            ));
        }
      })()}
    </Box>
  );
};

export default DialerAgencyMembersSearch;
