import React from "react";
import { Messages } from "../../core/api";
import { auth } from "../../core/auth";
import UnauthorizedPage from "./UnauthorizedPage";

export function AuthPermissionGuard(props: {
  children: React.ReactNode;
  permission: Messages["AgencyMemberPermission"];
  fallback?: React.ReactNode;
}) {
  if (!auth.isAllowedTo(props.permission)) {
    return <>{props.fallback ?? <UnauthorizedPage />}</>;
  }

  return <>{props.children}</>;
}
