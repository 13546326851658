import { useCallbackRef } from "@chakra-ui/react";
import { atom, useAtom } from "jotai";
import { atomFamily } from "jotai/utils";
import { useCallback, useRef } from "react";

export interface UseDisclosureProps {
  isOpen?: boolean;
  isDefaultOpen?: boolean;
  onClose?(): void;
  onOpen?(): void;

  /**
   * Unique identifier for the disclosure.
   *
   * If you want to control the same disclosure from multiple call expressions, you can provide the same `id` to all of them.
   * If you don't provide an `id`, a random unique identifier will be generated for each call expression.
   */
  id?: string;
}

const disclosuresAtomFamily = atomFamily((id: string) => atom({ id, isOpen: false }));

export function useSharedDisclosure(props: UseDisclosureProps = {}) {
  const { onClose: onCloseProp, onOpen: onOpenProp, isOpen: isOpenProp, id } = props;

  const handleOpen = useCallbackRef(onOpenProp);
  const handleClose = useCallbackRef(onCloseProp);

  const uniqueIdRef = useRef(id ?? crypto.randomUUID());
  const sharedId = uniqueIdRef.current;

  const [state, setState] = useAtom(disclosuresAtomFamily(sharedId));

  const isOpen = isOpenProp !== undefined ? isOpenProp : state.isOpen;
  const isControlled = isOpenProp !== undefined;

  const onClose = useCallback(() => {
    if (!isControlled) {
      setState({ ...state, isOpen: false });
    }
    handleClose?.();
  }, [isControlled, handleClose, setState, state]);

  const onOpen = useCallback(() => {
    if (!isControlled) {
      setState({ ...state, isOpen: true });
    }
    handleOpen?.();
  }, [isControlled, handleOpen, setState, state]);

  const onToggle = useCallback(() => {
    if (isOpen) {
      onClose();
    } else {
      onOpen();
    }
  }, [isOpen, onOpen, onClose]);

  return {
    isOpen,
    onOpen,
    onClose,
    onToggle,
  };
}

export type UseDisclosureReturn = ReturnType<typeof useSharedDisclosure>;
