import { Box, Input, Spinner, Text } from "@chakra-ui/react";
import { useState } from "react";
import useSearchPhonebookContactsQuery from "../../modules/phonebook/hooks/useSearchPhonebookContactsQuery";
import { useDebounce } from "../hooks/useDebounce";
import EntityCard from "./EntityCard";

interface Props {
  isLoading: boolean;
  onClickDial: (E164number: string) => void;
}

const DialerPhonebookSearch = (props: Props) => {
  const [phonebookSearchText, setPhonebookSearchText] = useState("");
  const debouncedPhonebookSearchText = useDebounce(phonebookSearchText, 200);
  const phonebookContacts = useSearchPhonebookContactsQuery({
    searchTerm: debouncedPhonebookSearchText,
  });
  return (
    <>
      <Input
        placeholder="Search phonebook..."
        type="text"
        value={phonebookSearchText}
        onChange={(e) => setPhonebookSearchText(e.currentTarget.value)}
      />
      <Box>
        {(() => {
          switch (phonebookContacts.status) {
            case "pending":
              return <Spinner />;
            case "error":
              return <Text>Error loading phonebook</Text>;
            case "success": {
              if (phonebookContacts.data.length === 0) {
                return <Text>Not Found</Text>;
              }

              return phonebookContacts.data.map((phonebookContact) => (
                <EntityCard
                  key={phonebookContact.id}
                  boxProps={{
                    onClick: () => {
                      if (!props.isLoading) {
                        props.onClickDial(phonebookContact.phoneNumber);
                      }
                    },
                    cursor: props.isLoading ? undefined : "pointer",
                    mb: 2,
                    opacity: props.isLoading ? 0.5 : 1,
                    borderBottom: "1px",
                    borderColor: "gray.100",
                  }}
                  entity={{
                    type: "PhonebookContact",
                    id: phonebookContact.id,
                    name: phonebookContact.name,
                  }}
                />
              ));
            }
          }
        })()}
      </Box>
    </>
  );
};

export default DialerPhonebookSearch;
