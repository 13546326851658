import { FlexProps, useToast } from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { caregiverRegularMessageTemplateOptions } from "../../../shared/components/SmsSender/sms-sender.consts";
import {
  CaregiverSmsSenderProps,
  NewSmsTicketRequestBody,
  PhoneNumber,
  RegularMessage,
} from "../../../shared/components/SmsSender/sms-sender.types";
import SmsSender from "../../../shared/components/SmsSender/SmsSender";
import useApi from "../../../shared/hooks/useApi";
import { formatErrorResponse } from "../../../shared/utils/format-response-error";
import { phoneFormatter } from "../../../shared/utils/phone-formatter";

const caregiverMessageTemplateOptions: RegularMessage[] = [
  ...Object.values(caregiverRegularMessageTemplateOptions),
].sort((a, b) => a.label.localeCompare(b.label));

type Props = Omit<CaregiverSmsSenderProps, "entity"> & {
  flexProps?: FlexProps;
  onClose: () => void;
};

function CaregiverSmsSender(props: Props) {
  const { api, queries } = useApi();
  const toast = useToast();

  const caregiverQuery = useQuery({
    ...queries.caregiver.get(props.caregiverId),
    select: (caregiver) => {
      const phoneNumbers: PhoneNumber[] = caregiver.phoneNumbers.map((x) => ({
        label: `${caregiver.firstName} ${
          caregiver.lastName
        } [ ${phoneFormatter.formatNationalIfValid(x.phoneNumber)} ]`,
        value: x.phoneNumber,
      }));
      return {
        phoneNumbers,
        firstName: caregiver.firstName,
        status: caregiver.status,
      };
    },
  });

  const createTicket = useMutation({
    mutationFn: (newTicketRequest: Pick<NewSmsTicketRequestBody, "phoneNumber" | "message">) => {
      return api.post("./comm_center/sms", {
        body: {
          ...newTicketRequest,
          message: newTicketRequest.message,
          phoneNumber: newTicketRequest.phoneNumber,
          caregiverId: props.caregiverId,
          patientId: null,
          topic: "Caregiver",
        },
      });
    },
    onSuccess: () => {
      toast({
        title: "SMS sent successfully",
        status: "success",
        position: "top-right",
      });
      props.onClose();
    },
    onError: (error) => {
      toast({
        title: "Could not create new ticket",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  return (
    <SmsSender
      entityQuery={caregiverQuery}
      flexProps={props.flexProps}
      isSending={createTicket.isPending}
      messageTemplates={caregiverMessageTemplateOptions}
      predefinedMessageTemplate={props.predefinedMessageTemplate}
      predefinedPhoneNumber={props.predefinedPhoneNumber}
      onClose={props.onClose}
      onSend={createTicket.mutate}
    />
  );
}

export default CaregiverSmsSender;
