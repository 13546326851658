import LogRocket from "logrocket";
import { Messages } from "../../core/api";
import { CommCenterTicketId, TelephonyCallId } from "../schema/schema";

type LogRocketInitParams = {
  agencyMember: Messages["AgencyMember"];
  permissions?: Messages["AgencyMemberPermission"][];
  userId: Messages["UserId"];
};

type LogRocketEvent =
  | {
      eventType: "CallAnswered";
      ticketId: CommCenterTicketId;
      callId: TelephonyCallId;
    }
  | {
      eventType: "CallTicketAnswered";
      ticketId: CommCenterTicketId;
    }
  | { eventType: "CallStartedRinging"; ticketId: CommCenterTicketId; callId: TelephonyCallId }
  | {
      eventType: "CallDialed";
      destination: string;
    }
  | {
      eventType: "PickPortalCall";
      destination: string;
    };

let hasInitialized = false;

export default {
  init: ({ agencyMember, userId }: LogRocketInitParams) => {
    console.log("[analytics] init");

    LogRocket.init("qlu1lh/webapp-production-e9pxo");

    const id = `${agencyMember.email} (${agencyMember.firstName} ${agencyMember.lastName})`;

    const traits = {
      agencyMemberId: agencyMember.id,
      userId: userId,
    };

    LogRocket.identify(id, traits);

    hasInitialized = true;
  },

  track: (event: LogRocketEvent) => {
    if (!hasInitialized) {
      return;
    }

    console.log("[analytics] track", event);

    const { eventType, ...payload } = event;

    LogRocket.track(eventType, payload);
  },
};
