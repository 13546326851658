import { addClassNamesToElement } from "@lexical/utils";
import type { NodeKey, SerializedLexicalNode, Spread } from "lexical";

import {
  $applyNodeReplacement,
  DecoratorNode,
  type DOMConversionMap,
  type DOMConversionOutput,
  type DOMExportOutput,
  type EditorConfig,
  type LexicalNode,
} from "lexical";
import { z } from "zod";
import SafeHTML from "../../SafeHTML";
import { chakra } from "@chakra-ui/react";

const zEmailReplyChain = z.object({
  citation: z.string(),
  content: z.string(),
});
type EmailReplyChainItem = z.infer<typeof zEmailReplyChain>;

const EmailReplyBlockquoteStyles = {
  marginLeft: "8px",
  borderLeft: "1px solid rgb(204, 204, 204)",
  paddingLeft: "10px",
};

export type SerializedEmailReplyChainNode = Spread<
  {
    replyChain: EmailReplyChainItem;
  },
  SerializedLexicalNode
>;

function convertEmailReplyChainElement(domNode: HTMLElement): DOMConversionOutput | null {
  const citationNode = domNode.querySelector("p");
  const contentNode = domNode.querySelector("blockquote");

  if (citationNode === null || contentNode === null || citationNode.textContent === null) {
    return null;
  }

  const citation = citationNode.textContent;
  const content = contentNode.innerHTML;

  const node = $createEmailReplyChainNode({ citation, content });
  return { node };
}

function createCitationElement(parent: HTMLElement, citation: string) {
  const citationElement = document.createElement("p");
  citationElement.textContent = citation;
  parent.appendChild(citationElement);
}

function createContentElement(parent: HTMLElement, content: string) {
  const contentElement = document.createElement("blockquote");
  const { marginLeft, borderLeft, paddingLeft } = EmailReplyBlockquoteStyles;

  contentElement.style.marginLeft = marginLeft;
  contentElement.style.borderLeft = borderLeft;
  contentElement.style.paddingLeft = paddingLeft;
  contentElement.innerHTML = content;

  parent.appendChild(contentElement);
}

export class EmailReplyChainNode extends DecoratorNode<JSX.Element> {
  __reply_chain: EmailReplyChainItem;

  constructor(replyChain: EmailReplyChainItem, key?: NodeKey) {
    super(key);
    this.__reply_chain = replyChain;
  }

  static getType(): string {
    return "email-reply-chain";
  }

  static clone(node: EmailReplyChainNode): EmailReplyChainNode {
    return new EmailReplyChainNode(node.__reply_chain);
  }

  static importJSON(serializedNode: SerializedEmailReplyChainNode): EmailReplyChainNode {
    const node = $createEmailReplyChainNode(serializedNode.replyChain);
    return node;
  }

  exportJSON(): SerializedEmailReplyChainNode {
    return {
      type: "email-reply-chain",
      replyChain: this.__reply_chain,
      version: 1,
    };
  }

  createDOM(_config: EditorConfig): HTMLElement {
    const element = document.createElement("div");
    addClassNamesToElement(element, "email-reply-chain");
    return element;
  }

  decorate(): JSX.Element {
    return (
      <EmailReplyChain
        citation={this.__reply_chain.citation}
        content={this.__reply_chain.content}
      />
    );
  }

  static importDOM(): DOMConversionMap | null {
    return {
      div: (domNode: HTMLElement) => {
        if (!domNode.hasAttribute("data-lexical-email-reply-chain")) {
          return null;
        }
        return {
          conversion: convertEmailReplyChainElement,
          priority: 4,
        };
      },
    };
  }

  updateDOM(_prevNode: EmailReplyChainNode, _dom: HTMLElement): boolean {
    return (
      _prevNode.__reply_chain.citation !== this.__reply_chain.citation ||
      _prevNode.__reply_chain.content !== this.__reply_chain.content
    );
  }

  getCitation(): string {
    return this.__reply_chain.citation;
  }

  getContent(): string {
    return this.__reply_chain.content;
  }

  setCitation(citation: string) {
    const writable = this.getWritable();
    writable.__reply_chain.citation = citation;
  }

  setContent(content: string) {
    const writable = this.getWritable();
    writable.__reply_chain.content = content;
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement("div");
    element.setAttribute("data-lexical-email-reply-chain", "true");
    createCitationElement(element, this.__reply_chain.citation);
    createContentElement(element, this.__reply_chain.content);
    return { element };
  }

  canInsertTextBefore(): boolean {
    return true;
  }

  canInsertTextAfter(): boolean {
    return true;
  }
}

export function $createEmailReplyChainNode(
  replyChainItem: EmailReplyChainItem
): EmailReplyChainNode {
  const replyChainNode = new EmailReplyChainNode(replyChainItem);
  return $applyNodeReplacement(replyChainNode);
}

export function $isEmailReplyChainNode(
  node: LexicalNode | null | undefined
): node is EmailReplyChainNode {
  return node instanceof EmailReplyChainNode;
}

function EmailReplyChain(props: { citation: string; content: string }) {
  const { marginLeft, borderLeft, paddingLeft } = EmailReplyBlockquoteStyles;
  return (
    <div data-lexical-email-reply-chain={true}>
      <p>{props.citation}</p>
      <blockquote style={{ marginLeft, borderLeft, paddingLeft }}>
        <EmailReplyChain.HTMLWrapper>
          <SafeHTML html={props.content} />
        </EmailReplyChain.HTMLWrapper>
      </blockquote>
    </div>
  );
}

EmailReplyChain.HTMLWrapper = chakra("div", {
  baseStyle: {
    table: {
      width: "fit-content !important",
    },
    "[width]": {
      overflow: "auto",
      maxWidth: "600px",
      whiteSpace: "break-spaces",
    },
  },
});
