import _ from "lodash";

//! @ngInject
export function adminRateSetupModalCtrl($scope, fields, data, FormService, DatabaseApi, toaster, $rootScope, $uibModal, NgTableParams, Currency) {
    $scope.hideSaveFormButton = true;
    $scope.title = $scope.viewModeOnly ? "View rate" : "Add rate";
    $scope.fields = FormService.getFields(fields, data, $scope.viewModeOnly);
    $scope.data = data;
    $scope.form = {};
    $scope.contractTypeId = $scope.contractId;
    $scope.enableEditRate = false;
    $scope.changedTierIds = new Set();
    
    $scope.data.rates = $scope.data.rates ?? [];
    $scope.data.rates.forEach(rate => rate.rateDollars = rate.rateCents / 100);

    $scope.editRate = function () {
        $scope.enableEditRate = !$scope.enableEditRate;
    }

    $scope.addNewTier = function () {
        $scope.data.rates.push({
            rateCents: 0,
            rateDollars: 0,
            tierHoursMinimum: null,
            tierHoursMaximum: null,
            rateCode: null,
            revenueCode: null,
            procedureCode: null,
            serviceUnits: 1
        });
    }

    $scope.onRateValueChange = function (id) {
        $scope.changedTierIds.add(id);
    }

    $scope.saveRate = function () {
        if ($scope.viewModeOnly) {
            let editedRates = $scope.data.rates
                .filter(r => $scope.changedTierIds.has(r.id));

            for (const rate of editedRates) {
                rate.rateCents = new Currency().fromMajor(rate.rateDollars ?? 0).toMinor();
            }            
            
            let editBody = {
                tiers: editedRates
            }

            DatabaseApi.put('agencies/' + $rootScope.agencyId + '/admin_rate/' + $scope.data.id + '/tiers' , editBody).then(function (res) {
                $scope.changedTierIds = new Set();
                $scope.getAdminRates();
                // I'm not sure why but this causes an angularJS inprog error.
                // As far as I can tell my current changes has no effect on this
                // and for some reason it doesn't happen in production.
                $scope.$close();
                toaster.pop('success', "Rate tier values was saved");
            }, function (err) {
                $scope.changedTierIds = new Set();
                toaster.pop('error', "Something went wrong", "Could not edit rate tier values");
            });
        } else {
            $scope.form.data.$submitted = true

            if (!validateRateForm()) {
                return;
            }

            $uibModal.open({
                templateUrl: 'admin/views/update-invoice-rates-modal.html',
                size: 'md',
                controller: 'updateInvoiceRatesModalCtrl',
                windowTopClass: "boost-modal"
            }).result.then((modalResult) => {
                if (modalResult !== 'discard') {
                    $scope.updateExistingInvoiceRatesData = modalResult;
                }

                const formData = FormService.prepareFormData($scope.fields);
                prepareAdminRateRequest(formData);

                DatabaseApi.post('agencies/' + $rootScope.agencyId + '/admin_rate', formData).then(function (res) {
                    $scope.getAdminRates();
                    $scope.$close();
                    toaster.pop('success', "A new rate was saved");
                }, function (err) {
                    toaster.pop('error', "Something went wrong", "Could not add a new rate");
                });

            });
        }
    };

    function validateRateForm() {
        if ($scope.form.data.$invalid) {
            return false;
        }

        for (const rate in $scope.data.rates) {
            if (rate.tierHoursMinimum === null) {
                toaster.pop('error', "Something went wrong", "Please fill in minimum hours for all tiers");
                return false;
            }
        }

        return true;
    }


    function prepareAdminRateRequest(formData) {
        formData.contractTypeId = $scope.contractTypeId;
        formData.rates = $scope.data.rates;
        for (const rate of formData.rates) {
            rate.rateCents = new Currency().fromMajor(rate.rateDollars ?? 0).toMinor();
        }
        if ($scope.updateExistingInvoiceRatesData) {
            formData.updateExistingInvoiceRatesData = $scope.updateExistingInvoiceRatesData;
        }
    }

    function setHistoryTableData() {
        var options = {
            count: 5,
            sorting: { createdAt: "desc" }
        };

        $scope.historyTableData = new NgTableParams(options, {
            counts: [],
            dataset: $scope.rateHistory
        });
    }

    setHistoryTableData();
};