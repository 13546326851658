import { auth } from "../../core/auth";
import useCallCenter from "../../modules/call-center/hooks/useCallCenter";
import { CallState } from "../../modules/call-center/hooks/useCallState";

const withCallCenterAuthorization = <P extends { state: CallState }>(
  WrappedComponent: React.ComponentType<P>
) => {
  const WithCallCenterAuthorizationComponent = (props: P) => {
    const { state } = useCallCenter();

    const isMedflytAgencyMember = auth.getAuthState().isMedflytAgencyMember ?? false;
    const isAuthorized = state.status !== "Unauthorized" && isMedflytAgencyMember;

    if (!isAuthorized) {
      return null;
    }

    return <WrappedComponent {...props} state={state} />;
  };

  const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || "Component";
  WithCallCenterAuthorizationComponent.displayName = `withCallCenterAuthorization(${wrappedComponentName})`;

  return WithCallCenterAuthorizationComponent;
};

export default withCallCenterAuthorization;
