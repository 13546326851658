import {
  Button,
  ButtonGroup,
  Center,
  Divider,
  Flex,
  Heading,
  Skeleton,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { auth } from "../../../../core/auth";
import ErrorPage from "../../../../shared/components/ErrorPage";
import Page from "../../../../shared/components/Page";
import useCountdown from "../../../../shared/hooks/useCountdown";
import { formatSeconds } from "../../../../shared/utils/date-utils";
import HumanTaskForm from "../../components/HumanTaskForm/HumanTaskForm";
import WorkflowTaskViewerTimerModal from "./WorkflowTaskViewerTimerModal";
import ClusteredTasks from "./components/ClusteredTasks";
import { useAssigneTasks } from "./hooks/useAssignedTasks";

const SECONDS_WARNING = 60;

export default function WorkflowTaskViewerPage() {
  const { prolong, query, skipTask, skipCluster, setActiveTaskInstanceId, activeTask, assign } =
    useAssigneTasks();
  const modal = useDisclosure();

  const seconds = useCountdown({
    endsAt: activeTask?.endsAt ?? null,
    onTick: (seconds) => {
      if (seconds === 0) {
        modal.onClose.call(null);
      }

      if (seconds === SECONDS_WARNING) {
        modal.onOpen.call(null);
      }
    },
  });

  const handleClickTimer = () => {
    if (seconds === null || seconds > SECONDS_WARNING) {
      return;
    }

    modal.onOpen.call(null);
  };

  if (query.isPending) {
    return <WorkflowTaskViewerPage.SkeletonPage />;
  }

  if (query.isError) {
    return <ErrorPage error={query.error} resetErrorBoundary={query.refetch} />;
  }

  if (query.data.tasks.length === 0) {
    return <WorkflowTaskViewerPage.NoTasksLeftPage onRefetch={query.refetch} />;
  }

  if (seconds === 0) {
    return (
      <WorkflowTaskViewerPage.TimedOutPage
        isLoading={query.isPending || query.isFetching}
        onRefetch={query.refetch}
      />
    );
  }

  if (activeTask === undefined) {
    console.error("activeTask is undefined", JSON.stringify(query.data, null, 2));
    return <></>;
  }

  return (
    <>
      <Page isLoading={query.isRefetching}>
        <Page.Header>
          <Page.Title>{activeTask.instance.meta.name}</Page.Title>
          <Flex gap={4}>
            {auth.isAllowedTo("skip_workflow_task") && (
              <ButtonGroup isAttached>
                <Button
                  isLoading={skipTask.isPending}
                  variant="outline"
                  onClick={() => skipTask.mutate({ id: activeTask.instance.id })}
                >
                  Skip task
                </Button>

                {query.data.cluster !== null && query.data.cluster.tasksCount > 1 && (
                  <Button
                    isLoading={skipCluster.isPending}
                    variant="outline"
                    onClick={() => skipCluster.mutate()}
                  >
                    Skip cluster
                  </Button>
                )}
              </ButtonGroup>
            )}

            {seconds !== null && (
              <Heading
                color={seconds < SECONDS_WARNING ? "red" : "black"}
                size="lg"
                onClick={handleClickTimer}
              >
                {formatSeconds(seconds)}
              </Heading>
            )}
          </Flex>
        </Page.Header>

        <Divider />

        {query.data.cluster !== null && query.data.cluster.tasksCount > 1 && (
          <ClusteredTasks
            activeTaskInstanceId={activeTask.instance.id}
            cluster={query.data.cluster}
            isButtonLoading={(task) =>
              assign.isPending && assign.variables?.id === task.instance.id
            }
            tasks={query.data.tasks}
            onChangeActiveTask={(x) => setActiveTaskInstanceId(x.instance.id)}
            onRequestAssign={(instance) => assign.mutate({ id: instance.id })}
          />
        )}

        <Divider />

        <Page.Content p={0}>
          <HumanTaskForm task={activeTask.instance} onSubmit={query.refetch} />
        </Page.Content>
      </Page>

      <WorkflowTaskViewerTimerModal
        isOpen={modal.isOpen}
        isProlonging={prolong.isPending}
        seconds={seconds ?? 0}
        onClose={modal.onClose}
        onProlong={() => prolong.mutate({ id: activeTask.instance.id })}
      />
    </>
  );
}

function SkeletonPage() {
  return (
    <Page>
      <Page.Header>
        <Page.Title>
          <Skeleton h={9} rounded="full" w={80} />
        </Page.Title>
        <Flex gap={4}>
          <Skeleton h={9} rounded="full" w={56} />
          <Skeleton h={9} rounded="full" w={20} />
        </Flex>
      </Page.Header>

      <Divider />

      <ClusteredTasks.Skeleton />

      <Divider />

      <Page.Content>
        <Center minH="md">
          <Spinner color="gray" size="xl" />
        </Center>
      </Page.Content>
    </Page>
  );
}
WorkflowTaskViewerPage.SkeletonPage = SkeletonPage;

function TimedOutPage(props: { isLoading: boolean; onRefetch: () => void }) {
  return (
    <Page>
      <Page.Header>
        <Heading size="lg">Task has timed out</Heading>
      </Page.Header>

      <Divider />

      <Page.Content>
        <Flex alignItems="center" direction="column" gap={4}>
          <Text>You have not completed this task on time.</Text>
          <Button isLoading={props.isLoading} onClick={props.onRefetch}>
            Continue
          </Button>
        </Flex>
      </Page.Content>
    </Page>
  );
}
WorkflowTaskViewerPage.TimedOutPage = TimedOutPage;

function NoTasksLeftPage(props: { onRefetch: () => void }) {
  const RELOAD_INTERVAL = 5;
  const [seconds, setSeconds] = React.useState(0);
  const secondsLeft = RELOAD_INTERVAL - (seconds % RELOAD_INTERVAL);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((s) => s + 1);

      if (secondsLeft === 1) {
        props.onRefetch.call(null);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [props.onRefetch, secondsLeft]);

  return (
    <Page>
      <Page.Header>
        <Heading size="lg">No tasks left</Heading>
      </Page.Header>

      <Divider />

      <Page.Content alignItems="center" display="flex" flex={1} justifyContent="center">
        <Flex alignItems="center" direction="column" gap={4}>
          <Flex alignItems="center" direction="column" gap={2} textAlign="center">
            <Heading size="lg">Good Job!</Heading>
            <Text>You have no pending tasks.</Text>
          </Flex>
          <Text>
            Checking for new tasks in <b>{secondsLeft}</b> seconds...
          </Text>
        </Flex>
      </Page.Content>
    </Page>
  );
}
WorkflowTaskViewerPage.NoTasksLeftPage = NoTasksLeftPage;
