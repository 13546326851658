import { useCallbackRef, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useRouter } from "@uirouter/react";
import React from "react";
import { Messages } from "../../../core/api";
import useApi from "../../../shared/hooks/useApi";
import useReactBroadcastChannel from "../../../shared/hooks/useReactBroadcastChannel";
import { CommCenterTicketId, PatientId, TelephonyCallId } from "../../../shared/schema/schema";
import createBrowserNotification from "../../../shared/utils/create-browser-notification";
import { formatErrorResponse } from "../../../shared/utils/format-response-error";
import logrocket from "../../../shared/utils/logrocket";
import { useCommCenterTicketPopup } from "../../communication/hooks/useCommCenterTicketPopup";
import { AlreadyInACallOnAnotherTabError } from "../call-center.errors";
import { isIntakeTeam, validateMicrophone } from "../call-center.utils";
import useAlreadyInACallDialog from "./useAlreadyInACallDialog";
import useCallState from "./useCallState";
import usePhoneProvider from "./usePhoneProvider";

export default function useCallCenter() {
  const [state, dispatch] = useCallState();
  const commCenterTicketPopup = useCommCenterTicketPopup();
  const { broadcastEvent } = useReactBroadcastChannel();
  const { stateService } = useRouter();
  const { api } = useApi();
  const toast = useToast();
  const phoneProvider = usePhoneProvider();
  const alreadyInACallDialog = useAlreadyInACallDialog();
  const providerHangupCallRef = useCallbackRef(phoneProvider.hangupCall);

  const declineMutation = useMutation({
    mutationFn: (params: { callId: TelephonyCallId }) => {
      return api.post("./telephony/calls/:callId/decline", { path: { callId: params.callId } });
    },
  });

  const cancelRinging = () => {
    if (state.status !== "Ringing") {
      return;
    }

    if (window.document.visibilityState === "visible") {
      broadcastEvent("CALL_CENTER:DECLINE", { callId: state.currentCall.id });
    }

    dispatch({ type: "Cancel" });
  };

  const decline = () => {
    if (state.status !== "Ringing") {
      return;
    }

    if (state.currentCall.direction === "Inbound") {
      declineMutation.mutate({ callId: state.currentCall.id });
    }

    broadcastEvent("CALL_CENTER:DECLINE", { callId: state.currentCall.id });
    dispatch({ type: "Decline" });
  };

  const hangup = React.useCallback(() => {
    if (state.status === "Active") {
      providerHangupCallRef();
      dispatch({ type: "Hangup" });
    }
  }, [dispatch, providerHangupCallRef, state.status]);

  const removedFromCall = () => {
    dispatch({ type: "RemovedFromCall" });
  };

  const ring = (
    payload: Messages["TelephonyInboundCallInfo"] | Messages["TelephonyPortalRequestCallInfo"]
  ) => {
    if (payload.direction === "Inbound") {
      logrocket.track({
        eventType: "CallStartedRinging",
        ticketId: payload.ticket.id,
        callId: payload.id,
      });
    }

    dispatch({ type: "Ring", payload });
    createBrowserNotification({ title: "Incoming call" });
  };

  const connectOutbound = (payload: Messages["TelephonyOutboundCallInfo"]) => {
    dispatch({ type: "ConnectOutbound", payload });
  };

  const connectParallel = (payload: Messages["TelephonyParallelCallInfo"]) => {
    phoneProvider.startOutboundCall({ destination: payload.id });
    dispatch({ type: "ConnectParallel", payload });
  };

  const closeSummary = () => {
    dispatch({ type: "CloseSummary" });
  };

  const bargeIn = (payload: Messages["TelephonyInboundCallInfo"]) => {
    dispatch({ type: "BargeIn", payload });
  };

  const dialMutation = useMutation({
    mutationFn: async (body: { destination: string }) => {
      logrocket.track({ eventType: "CallDialed", destination: body.destination });

      await validateMicrophone();
      await phoneProvider.login();

      const callId = crypto.randomUUID();
      phoneProvider.startOutboundCall({ destination: callId });

      return api.post("./telephony/calls/make", {
        body: {
          destination: body.destination,
          callId: TelephonyCallId.parse(callId),
        },
      });
    },
    onSuccess: ({ assignResult }) => {
      if (assignResult.status === "ERROR") {
        switch (assignResult.reason) {
          case "CALL_ALREADY_ASSIGNED":
            toast({
              title: "Call already answered",
              description: "This is probably a bug",
              status: "error",
              position: "top-right",
            });
            break;
          case "MEMBER_ALREADY_ASSIGNED":
            toast({
              title: "Already in a call",
              description:
                "This is probably a bug. It seems like you're in a different call already",
              status: "error",
              position: "top-right",
            });
        }
      }
    },
    onError: (error) => {
      if (state.status === "Dialing") {
        dispatch({ type: "Decline" });
      }

      if (error instanceof AlreadyInACallOnAnotherTabError) {
        alreadyInACallDialog.open();
        return;
      }

      toast({
        title: "Could not start outbound call",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const dial = (destination: string) => {
    dialMutation.mutate({ destination });
    dispatch({ type: "Dial", destination });
  };

  const toggleMute = () => {
    state.isMuted ? phoneProvider.unmuteCall() : phoneProvider.muteCall();
    dispatch({ type: "ToggleMute" });
  };

  const answer = useMutation({
    mutationFn: async (params: {
      call: Messages["TelephonyInboundCallInfo"];
      intent: Messages["TelephonyCallAnswerIntent"];
    }) => {
      logrocket.track({
        eventType: "CallAnswered",
        ticketId: params.call.ticket.id,
        callId: params.call.id,
      });

      await validateMicrophone();

      await phoneProvider.login();

      const { result } = await api.post("./telephony/calls/:callId/answer", {
        path: { callId: params.call.id },
        body: { intent: params.intent },
      });

      answerCallback({ result: result, call: params.call });
    },
    onError: (error) => {
      if (error instanceof AlreadyInACallOnAnotherTabError) {
        alreadyInACallDialog.open();
        return;
      }

      toast({
        title: "Could not answer call",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const answerByTicket = useMutation({
    mutationFn: async (params: {
      ticketId: CommCenterTicketId;
      intent: Messages["TelephonyCallAnswerIntent"];
    }) => {
      logrocket.track({ eventType: "CallTicketAnswered", ticketId: params.ticketId });

      await phoneProvider.login();

      return api.post("./telephony/tickets/:ticketId/answer", {
        path: { ticketId: params.ticketId },
        body: { intent: params.intent },
      });
    },
    onError: (error) => {
      if (error instanceof AlreadyInACallOnAnotherTabError) {
        alreadyInACallDialog.open();
        return;
      }

      toast({
        title: "Could not answer call",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
    onSuccess: ({ result, call }) => answerCallback({ result: result, call: call }),
  });

  const answerCallback = (params: {
    result: Messages["TelephonyCallAnswerResult"];
    call: Messages["TelephonyCallInfo"];
  }) => {
    const { result, call } = params;

    if (result.status === "ERROR") {
      switch (result.reason) {
        case "CALL_ALREADY_ASSIGNED":
          toast({
            title: "Call already answered",
            description: `${result.to.name} has already answered this call`,
            status: "success",
            position: "top-right",
          });
          break;

        // Agency member shouldn't receive this kind of error due to the (current) inbound call flow,
        // but just for the sake of completeness, we'll handle this error as well.
        case "MEMBER_ALREADY_ASSIGNED":
          toast({
            title: "Already in a call",
            description: "This is probably a bug. It seems like you're in a different call already",
            status: "error",
            position: "top-right",
          });
          break;

        // Usually agency member won't answer a non-invitation call as invitation,
        // but just for the sake of completeness, we'll handle this error as well.
        case "MEMBER_NOT_INVITED":
          toast({
            title: "Invitation required",
            description:
              "You can't accept this call as an invitation since you are not invited to this call",
            status: "error",
            position: "top-right",
          });
          break;
      }

      dispatch({ type: "Decline" });

      return;
    }

    // Technically this should never happen when trying to answer a call
    if (call.provider.callId === null) {
      toast({
        title: "Could not answer call",
        description: "Call ID is missing",
        status: "error",
        position: "top-right",
      });

      dispatch({ type: "Decline" });

      return;
    }

    broadcastEvent("CALL_CENTER:ANSWER_INBOUND", { call });
    dispatch({ type: "Answer", payload: call });

    openCallTicketPopup(call);

    phoneProvider.answerIncomingCall({ destination: call.provider.callId });

    if (call.ticket.teamName === "Intake") {
      if (call.caller?.entity.type === "Patient") {
        stateService.go("app.patients.intake-flow", {
          patientId: call.caller.entity.id,
        });
      }

      if (call.caller?.entity.type === "PatientContact") {
        stateService.go("app.patients.intake-flow", {
          patientId: call.caller.entity.patient.id,
        });
      }
    }
  };

  const openCallTicketPopup = (call: Messages["TelephonyCallInfo"]): void => {
    const entity = getTelephonyCallPrimaryEntity(call);

    if (entity === null) {
      toast({
        title: "Could not open ticket",
        description: "No primary entity found. This is probably a bug.",
        status: "error",
        position: "top-right",
      });
      return;
    }

    switch (entity.type) {
      case "Patient":
        return commCenterTicketPopup.open({
          primaryEntity: "Patient",
          patientId: entity.id,
          contactDetails: null,
          defaultTicketId: call.ticket.id,
          defaultMinimized: isIntakeTeam(call.ticket.teamName),
        });
      case "PatientContact":
        return commCenterTicketPopup.open({
          primaryEntity: "Patient",
          patientId: entity.patient.id,
          contactDetails: entity,
          defaultTicketId: call.ticket.id,
          defaultMinimized: isIntakeTeam(call.ticket.teamName),
        });
      case "PhonebookContact":
        return commCenterTicketPopup.open({
          primaryEntity: "PhonebookContact",
          phonebookContactId: entity.id,
          defaultTicketId: call.ticket.id,
        });
      case "NotIdentified":
        return commCenterTicketPopup.open({
          primaryEntity: "NotIdentifiedPhoneNumber",
          notIdentifiedPhoneNumber: entity.source,
          defaultTicketId: call.ticket.id,
          defaultMinimized: isIntakeTeam(call.ticket.teamName),
        });
      case "Caregiver":
        return commCenterTicketPopup.open({
          primaryEntity: "Caregiver",
          caregiverId: entity.id,
          defaultTicketId: call.ticket.id,
        });
    }
  };

  const setAfterCallCallTypeData = (
    params: {
      callId: TelephonyCallId;
      patientId: PatientId;
    } | null
  ) => {
    dispatch({
      type: "SetAfterCallCallTypeData",
      payload: params,
    });
  };

  const setManuallyNextCallDateData = (
    params: {
      patientId: PatientId;
    } | null
  ) => {
    dispatch({
      type: "SetManuallyNextCallDateData",
      payload: params,
    });
  };

  const connectTransferedCall = (payload: Messages["TelephonyOutboundCallInfo"]) => {
    dispatch({ type: "ConnectTransferedCall", payload });
  };

  const pickPortalCallRequest = useMutation({
    mutationFn: async (body: { destination: string; callId: TelephonyCallId }) => {
      logrocket.track({ eventType: "PickPortalCall", destination: body.destination });

      await validateMicrophone();

      await phoneProvider.login();

      phoneProvider.startOutboundCall({ destination: body.callId });

      return api.post("./telephony/calls/make", {
        body: {
          destination: body.destination,
          callId: TelephonyCallId.parse(body.callId),
        },
      });
    },
    onSuccess: ({ assignResult }, { destination }) => {
      if (assignResult.status === "ERROR") {
        switch (assignResult.reason) {
          case "CALL_ALREADY_ASSIGNED":
            toast({
              title: "Call already answered",
              description: "Portal call request has already been answered",
              status: "error",
              position: "top-right",
            });
            break;
          case "MEMBER_ALREADY_ASSIGNED":
            toast({
              title: "Already in a call",
              description: "It seems like you're in a different call already",
              status: "error",
              position: "top-right",
            });
        }

        dispatch({ type: "Decline" });

        return;
      }

      dispatch({ type: "Dial", destination });
    },
    onError: (error) => {
      if (state.status === "Dialing") {
        dispatch({ type: "Decline" });
      }

      if (error instanceof AlreadyInACallOnAnotherTabError) {
        alreadyInACallDialog.open();
        return;
      }

      toast({
        title: "Could not pick portal call request",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const updateActiveCall = (call: Messages["TelephonyCallInfo"]) => {
    dispatch({ type: "UpdateActiveCall", payload: call });
  };

  return {
    state,
    openCallTicketPopup,
    dial,
    answer,
    answerByTicket,
    hangup,
    decline,
    cancelRinging,
    ring,
    bargeIn,
    connectOutbound,
    connectParallel,
    closeSummary,
    toggleMute,
    setAfterCallCallTypeData,
    setManuallyNextCallDateData,
    removedFromCall,
    connectTransferedCall,
    pickPortalCallRequest,
    alreadyInACallDialog,
    updateActiveCall,
  };
}

function getTelephonyCallPrimaryEntity(
  call: Messages["TelephonyCallInfo"]
): Exclude<
  Messages["TelephonyCallParticipantInfo"]["entity"],
  Messages["TelephonyCallAgencyMemberParticipantEntityInfo"]
> | null {
  switch (call.direction) {
    case "Inbound":
      switch (call.caller.entity.type) {
        case "AgencyMember": {
          for (const participant of call.participants) {
            if (participant.entity.type !== "AgencyMember") {
              return participant.entity;
            }
          }

          return null;
        }

        case "Patient":
        case "PatientContact":
        case "PhonebookContact":
        case "NotIdentified":
        case "Caregiver":
          return call.caller.entity;

        default:
          call.caller.entity satisfies never;
          return null;
      }

    case "Outbound":
    case "Parallel":
      switch (call.callee.entity.type) {
        case "Patient":
        case "PatientContact":
        case "PhonebookContact":
        case "NotIdentified":
        case "Caregiver":
          return call.callee.entity;
      }
  }
}
