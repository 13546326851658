import { Instant } from "@js-joda/core";
import { jwtDecode } from "jwt-decode";
import { env } from "../env";
import { UserId } from "../shared/schema/schema";
import { Messages, ResponseOf } from "./api";
import { memoize } from "../shared/utils/memoize";

const memoizedJwtDecode = memoize(jwtDecode) as typeof jwtDecode;

const storageKey = "user";

interface AuthInfo {
  authToken: string;
  refreshToken: string;
  permissions: Messages["AgencyMemberPermission"][];
}

interface AuthData {
  userId: UserId;
  agencyMember: Messages["AgencyMember"];
  agency: Messages["Agency"];
}

interface JWTResponse {
  userLoginDetails: {
    agencyMember: Messages["AgencyMember"];
    agency: Messages["Agency"];
  };
  authData: {
    userId: UserId;
    userCreatedAt: Instant;
    superuser: boolean;
    email: string | null;
  };
}

export const auth = {
  getAuthInfo: () => {
    const user = localStorage.getItem(storageKey);
    return user ? (JSON.parse(user) as AuthInfo) : null;
  },
  getAuthState: () => {
    const authInfo = auth.getAuthInfo();

    if (authInfo === null) {
      return { type: "guest" as const };
    }

    try {
      const { authData, userLoginDetails } = memoizedJwtDecode<JWTResponse>(authInfo.authToken);

      const data: AuthData = {
        userId: authData.userId,
        agency: userLoginDetails.agency,
        agencyMember: userLoginDetails.agencyMember,
      };

      return {
        type: "authenticated" as const,
        isMedflytAgencyMember: data.agency.id === env.MEDFLYT_AGENCY_ID,
        data: data,
        tokens: {
          authToken: authInfo.authToken,
          refreshToken: authInfo.refreshToken,
        },
      };
    } catch (error) {
      console.error("Failed to decode JWT", { error, authToken: authInfo.authToken });
      throw error;
    }
  },
  getAuthOrFail: () => {
    const authState = auth.getAuthState();

    if (authState.type === "guest") {
      throw auth.logout();
    }

    return authState;
  },
  getTokens: () => {
    const user = auth.getAuthInfo();
    return user ? { authToken: user.authToken, refreshToken: user.refreshToken } : null;
  },
  getPermissions: () => {
    const user = auth.getAuthInfo();
    return user ? user.permissions : [];
  },
  set: (info: AuthInfo) => {
    const user = auth.getAuthInfo();

    if (user === null) {
      throw new Error("Can't set user without existing user");
    }

    const updated = { ...user, ...info };

    console.debug("[auth] setting user object (origin auth@set)", updated);
    localStorage.setItem(storageKey, JSON.stringify(updated));
  },
  setFromRefetch: (data: ResponseOf<"post", "/auth/token">) => {
    auth.set({
      authToken: data.accessJWT,
      refreshToken: data.refreshJWT,
      permissions: auth.getPermissions(),
    });
  },
  isAllowedTo: (permission: Messages["AgencyMemberPermission"]) => {
    const user = auth.getAuthInfo();
    return user !== null && user.permissions.includes(permission);
  },
  logout: () => {
    const clearExcept = [
      "stealthMode",
      "code",
      "showVideoNewVisitForm",
      "caregiversTableSettings",
      "trainingTableSettings",
      "pendingApplicationTableSettings",
      "coronaReportsTableSettings",
      "medflytPassportTableSettings",
      "billingAccountsReceivableTableSettings",
      "savedAssignedCoordinators",
      "faxComment",
      "localDistHash",
      "hide-sweep-vegas",
      "hide-reset-attempts-video",
      "hide-corona-banner",
      "trainingCenterStatisticsTableColumn",
      "trainingCenterOverallStatisticsTableColumn",
      "searchFiltersSettings",
      "visitsDashboardTableSettings",
      "stateOfHomeCareSurvey",
      "patientCalendarShowDeletedVisits",
      "caregiverCalendarShowDeletedVisits",
      "ClientSurveyNextDate",
    ];

    const localStorageKeys = Object.keys(localStorage);

    for (const key of localStorageKeys) {
      if (!clearExcept.includes(key)) {
        localStorage.removeItem(key);
      }
    }

    window.location.href = "/login";

    return new Error("Logged out");
  },
};
