import {
  Button,
  Center,
  Divider,
  Flex,
  FormLabel,
  Heading,
  Switch,
  Tab,
  TabList,
  Tabs,
  Wrap,
} from "@chakra-ui/react";
import { Table } from "@tanstack/react-table";
import { uniqBy } from "lodash";
import React from "react";
import { useDidMount } from "rooks";
import { Messages, QueryParamsOf } from "../../../../core/api";
import RangeDatePicker from "../../../../shared/components/DatePicker/RangeDatePicker";
import FreeTextSearch from "../../../../shared/components/FreeTextSearch";
import Page from "../../../../shared/components/Page";
import Select from "../../../../shared/components/Select";
import { caregiverStatus } from "../../../../shared/consts/caregiver-status";
import useAuthData from "../../../../shared/hooks/useAuthInfo";
import { createFilters } from "../../../../shared/hooks/useFilters";
import useLastRouteState from "../../../../shared/hooks/useLastRouteState";
import useLocalFilters from "../../../../shared/hooks/useLocalFilters";
import { useNewTicket } from "../../../../shared/hooks/useNewTicket";
import AddRoundedIcon from "../../../../shared/icons/AddRoundedIcon";
import { CommCenterLabelId } from "../../../../shared/schema/schema";
import { FilterProps } from "../../../../shared/utils/filter-props";
import TicketSummaryModal from "../../components/TicketSummaryModal";
import TicketsStats from "../../components/TicketsStats";
import TicketsTable, { TicketDataRow } from "../../components/TicketsTable";
import useGroupTicketsByPatient from "../../hooks/useGroupTicketsByPatient";
import useTicketSummaryDisclosure from "../../hooks/useTicketSummaryDisclosure";
import { CommCenterLocalFilters, commCenterTableLocalFilters } from "../../utils/table-utils";
import { shouldEnableHideVisitAssignmentRelatedTicketsByDefault } from "./OpenCommunicationsRoute";

const indexToTab: Record<number, "Unassigned" | "My" | "MyTeam" | "EntireAgency"> = {
  0: "Unassigned",
  1: "My",
  2: "MyTeam",
  3: "EntireAgency",
};

const tabToIndex: Record<"Unassigned" | "My" | "MyTeam" | "EntireAgency", number> = {
  Unassigned: 0,
  My: 1,
  MyTeam: 2,
  EntireAgency: 3,
};

interface Props extends FilterProps<"./comm_center/tickets"> {
  tickets: Messages["CommCenterTicket"][];
  teams: Messages["CommCenterTeamWithMembers"][];
  stages: Messages["HRSectionStage"][];
  labels: Messages["CommCenterLabel"][];
  ticketSources: Messages["CommCenterTicketSourceLabel"][];
  initialLabelId: CommCenterLabelId | null;
  stats: {
    unassigned: number;
    myTickets: number;
    myTeamTicket: number;
    allTickets: number;
  };
  isRefetching: boolean;
  ticketSummaryDisclosure: ReturnType<typeof useTicketSummaryDisclosure>;
}

const OpenCommunicationsPage = (props: Props) => {
  const { agencyMember } = useAuthData();
  const tableRef = React.useRef<Table<TicketDataRow>>(null);
  const lastRouteState = useLastRouteState();
  const { onOpen: openNewTicket } = useNewTicket();

  const handleChangeFilters: FilterProps<"./comm_center/tickets">["onChangeFilters"] = (
    filters
  ) => {
    tableRef.current?.setPageIndex(0);
    props.onChangeFilters(filters);
  };

  const handleChangeFilter: FilterProps<"./comm_center/tickets">["onChangeFilter"] = (
    name,
    value
  ) => {
    tableRef.current?.setPageIndex(0);
    props.onChangeFilter(name, value);
  };

  const { filtered: filteredTickets, ...localFilters } = useLocalFilters<
    Messages["CommCenterTicket"],
    CommCenterLocalFilters
  >({
    data: props.tickets,
    storageKey: ["comm-center", "tickets-table", "local-filters"],
    filters: commCenterTableLocalFilters,
  });

  const shouldLoadLocalFilterCache = lastRouteState?.name === "app.commcenter_ticket";

  useDidMount(() => {
    if (!shouldLoadLocalFilterCache) {
      localFilters.changeFilter("entity", "");
    }
  });

  const handleChangeLocalFilter: typeof localFilters["changeFilter"] = (path, value) => {
    tableRef.current?.setPageIndex(0);
    localFilters.changeFilter(path, value);
  };

  const {
    createSelectFilter: createLocalSelectFilter,
    createSwitchFilter: createLocalSwitchFilter,
  } = createFilters<CommCenterLocalFilters>();
  const awaitingResponseSelectFilter = createLocalSelectFilter({
    name: "awaitingResponse",
    disabled: false,
    label: "Awaiting Response",
    onChange: handleChangeLocalFilter,
    options: [
      {
        label: "Caregiver Response",
        value: "Caregiver",
      },
      {
        value: "Agency",
        label: "Agency Response",
      },
      {
        label: "All",
        value: "All",
      },
    ],
    value: localFilters.state.awaitingResponse ?? null,
  });

  const unreadSwitchFilter = createLocalSwitchFilter({
    name: "unread",
    onChange: handleChangeLocalFilter,
    value: localFilters.state.unread ?? false,
  });

  const { createMultiSelectFilter, createRangeDatePickerFilter, createSwitchFilter } =
    createFilters<QueryParamsOf<"get", "./comm_center/tickets">>();

  const hideVisitAssignmentRelatedTicketsSwitchFilter = createSwitchFilter({
    name: "hideVisitAssignmentRelatedTickets",
    onChange: handleChangeFilter,
    value: props.filters.getValueOrNull("hideVisitAssignmentRelatedTickets") ?? false,
  });

  const fromToFilter = createRangeDatePickerFilter({
    label: "Date from to",
    startDate: {
      name: "lastMessageFrom",
      value: props.filters.getValueOrNull("lastMessageFrom"),
    },
    endDate: {
      name: "lastMessageTo",
      value: props.filters.getValueOrNull("lastMessageTo"),
    },
    onChange: handleChangeFilter,
  });

  const caregiverStatusFilter = createMultiSelectFilter({
    name: "caregiverStatus",
    label: "Caregiver status",
    options: caregiverStatus.dropdown,
    value: props.filters.getValueOrNull("caregiverStatus"),
    onChange: handleChangeFilter,
  });

  const ticketStatusFilter = createMultiSelectFilter({
    name: "ticketStatus",
    label: "Ticket status",
    options: [
      { label: "New", value: "NEW" },
      { label: "In progress", value: "IN PROGRESS" },
      { label: "Resolved", value: "RESOLVED" },
    ],
    value: props.filters.getValueOrNull("ticketStatus"),
    onChange: handleChangeFilter,
  });

  const ticketCallStatusFilter = createMultiSelectFilter<
    "telephonyCallStatus",
    Messages["TelephonyCallStatus"]
  >({
    name: "telephonyCallStatus",
    label: "Call status",
    options: [
      { value: "Completed", label: "Completed" },
      { value: "Missed", label: "Missed" },
      { value: "Failed", label: "Failed" },
      { value: "MovedToSMS", label: "MovedToSMS" },
      { value: "NoAnswer", label: "NoAnswer" },
      { value: "VoiceMail", label: "VoiceMail" },
      { value: "Canceled", label: "Canceled" },
    ],
    value: props.filters.getValueOrNull("telephonyCallStatus"),
    onChange: handleChangeFilter,
  });

  const onboardingStageFilter = createMultiSelectFilter({
    name: "onboardingStage",
    label: "Onboarding stage",
    options: props.stages
      .map((stage) => ({
        label: `${stage.certification} - ${stage.label}`,
        value: stage.id,
      }))
      .sort((a, b) => a.label.localeCompare(b.label)),
    value: props.filters.getValueOrNull("onboardingStage"),
    onChange: handleChangeFilter,
  });

  const priorityFilter = createMultiSelectFilter({
    name: "priority",
    label: "Priority",
    options: [
      { label: "Low", value: "LOW" },
      { label: "Medium", value: "MEDIUM" },
      { label: "High", value: "HIGH" },
    ],
    value: props.filters.getValueOrNull("priority"),
    onChange: handleChangeFilter,
  });

  const labelFilter = createMultiSelectFilter({
    name: "labelId",
    label: "Label",
    options: props.labels
      .map((label) => ({ label: label.name, value: label.id }))
      .sort((a, b) => a.label.localeCompare(b.label)),
    value: props.filters.getValueOrNull("labelId"),
    onChange: handleChangeFilter,
  });

  const assigneeFilter = createMultiSelectFilter({
    name: "assignee",
    label: "Assignee",
    options: uniqBy(
      props.teams
        .flatMap((team) => team.members)
        .map((member) => ({
          label: [member.firstName, member.lastName].join(" "),
          value: member.agencyMemberId,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
      (x) => x.value
    ),
    value: props.filters.getValueOrNull("assignee"),
    onChange: handleChangeFilter,
  });

  const teamFilter = createMultiSelectFilter({
    name: "team",
    label: "Team",
    options: props.teams
      .map((team) => ({ label: team.name, value: team.id }))
      .sort((a, b) => a.label.localeCompare(b.label)),
    value: props.filters.getValueOrNull("team"),
    onChange: handleChangeFilter,
  });

  const ticketSourceFilter = createMultiSelectFilter({
    name: "ticketSource",
    label: "Source",
    onChange: handleChangeFilter,
    value: props.filters.getValueOrNull("ticketSource"),
    options: props.ticketSources.map((source) => ({
      label: source.label,
      value: source.source,
    })),
  });

  const entityFilter = createMultiSelectFilter({
    name: "topic",
    label: "Entity",
    onChange: handleChangeFilter,
    value: props.filters.getValueOrNull("topic"),
    options: [
      { value: "Caregiver", label: "Caregiver" },
      { value: "Patient", label: "Patient" },
      { value: "NotIdentifiedPhoneNumber", label: "Not identified phone number" },
    ],
  });

  const getMyTeam = () => {
    return props.teams.find((team) =>
      team.members.some((member) => member.agencyMemberId === agencyMember.id)
    );
  };

  const handleChangeTabIndex = (index: number) => {
    const tab = index in indexToTab ? indexToTab[index] : "Unassigned";

    switch (tab) {
      case "Unassigned":
        return handleChangeFilters({ unassigned: true, assignee: undefined, team: undefined });
      case "My":
        return handleChangeFilters({
          unassigned: undefined,
          assignee: [agencyMember.id],
          team: undefined,
        });
      case "MyTeam": {
        const team = getMyTeam();

        if (team !== undefined) {
          return handleChangeFilters({
            unassigned: undefined,
            assignee: undefined,
            team: [team.id],
          });
        }

        return;
      }
      case "EntireAgency":
        return handleChangeFilters({
          unassigned: undefined,
          assignee: undefined,
          team: undefined,
        });
    }
  };

  const getActiveTabIndex = () => {
    const assignee = props.filters.getValueOrNull("assignee");
    const team = props.filters.getValueOrNull("team");
    const unassigned = props.filters.getValueOrNull("unassigned");

    if (unassigned === true) {
      return tabToIndex["Unassigned"];
    }

    if (assignee !== null && assignee.length === 1 && assignee[0] === agencyMember.id) {
      return tabToIndex["My"];
    }

    if (team !== null && team.length === 1 && getMyTeam()?.id === team[0]) {
      return tabToIndex["MyTeam"];
    }

    return tabToIndex["EntireAgency"];
  };

  const { groupByPatient, groupedTickets, onChangeGroupByPatient } = useGroupTicketsByPatient({
    tickets: filteredTickets,
  });
  const handleChangeGroupByPatient = (value: boolean) => {
    tableRef.current?.setPageIndex(0);
    onChangeGroupByPatient(value);
  };

  const ticketsToShow = groupByPatient ? groupedTickets : filteredTickets;

  return (
    <Page isLoading={props.isRefetching}>
      <Page.Header>
        <Flex gap={8} justifyContent="space-between" wrap="wrap">
          <Heading size="lg">Open Conversations ({ticketsToShow.length})</Heading>
          <Tabs
            colorScheme="blue"
            index={getActiveTabIndex()}
            variant="soft-rounded"
            onChange={handleChangeTabIndex}
          >
            <TabList>
              <Wrap>
                <Tab>Unassigned Conversations ({props.stats.unassigned})</Tab>
                <Tab>My Conversations ({props.stats.myTickets})</Tab>
                <Tab>My Team Conversations ({props.stats.myTeamTicket})</Tab>
                <Tab>The entire agency ({props.stats.allTickets})</Tab>
              </Wrap>
            </TabList>
          </Tabs>
        </Flex>
      </Page.Header>

      <TicketsStats tickets={ticketsToShow} />

      <Page.Filters>
        <Flex direction="column" gap={3}>
          <Heading size="sm">Filters</Heading>
          <Flex direction="row" gap={3} wrap="wrap">
            <RangeDatePicker {...fromToFilter} />
            <FreeTextSearch
              debounce={300}
              inputProps={{
                w: "auto",
                defaultValue: shouldLoadLocalFilterCache ? localFilters.state.entity : "",
              }}
              placeholder="Entity ID, Name..."
              value={localFilters.state.entity ?? ""}
              onChange={(e) => handleChangeLocalFilter("entity", e ?? "")}
            />
            <Select {...caregiverStatusFilter} />
            <Select {...ticketStatusFilter} />
            <Select {...onboardingStageFilter} />
            <Select {...priorityFilter} />
            <Select {...labelFilter} />
            <Select {...assigneeFilter} />
            <Select {...teamFilter} />
            <Select {...ticketSourceFilter} />
            <Select {...entityFilter} />
            <Select {...awaitingResponseSelectFilter} />
            <Select {...ticketCallStatusFilter} />
            <Center padding={2}>
              <FormLabel htmlFor={unreadSwitchFilter.name}> Show Unread Only </FormLabel>
              <Switch
                id={unreadSwitchFilter.name}
                isChecked={unreadSwitchFilter.isChecked}
                onChange={(e) => {
                  unreadSwitchFilter.onChange(e.target.checked);
                }}
              />
            </Center>
            <Center padding={2}>
              <FormLabel htmlFor="groupByPatients">Group tickets by patients</FormLabel>
              <Switch
                id="groupByPatients"
                isChecked={groupByPatient}
                onChange={(e) => handleChangeGroupByPatient(e.target.checked)}
              />
            </Center>
            {!shouldEnableHideVisitAssignmentRelatedTicketsByDefault(agencyMember) && (
              <Center padding={2}>
                <FormLabel htmlFor={hideVisitAssignmentRelatedTicketsSwitchFilter.name}>
                  Hide visit assignment related tickets
                </FormLabel>
                <Switch
                  id={hideVisitAssignmentRelatedTicketsSwitchFilter.name}
                  isChecked={hideVisitAssignmentRelatedTicketsSwitchFilter.isChecked}
                  onChange={(e) => {
                    hideVisitAssignmentRelatedTicketsSwitchFilter.onChange(e.target.checked);
                  }}
                />
              </Center>
            )}
            <Flex gap={2} ms="auto">
              <Button
                colorScheme="blue"
                leftIcon={<AddRoundedIcon />}
                onClick={() => openNewTicket()}
              >
                New
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Page.Filters>

      <Divider />

      <Page.Content>
        <TicketsTable
          isGroupedByPatient={groupByPatient ?? false}
          tableRef={tableRef}
          tickets={ticketsToShow}
          onClickShowSummary={props.ticketSummaryDisclosure.disclosureProps.onOpen}
        />

        <TicketSummaryModal {...props.ticketSummaryDisclosure} />
      </Page.Content>
    </Page>
  );
};

export default OpenCommunicationsPage;
