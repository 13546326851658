import { TypedDocumentNode } from "@graphql-typed-document-node/core";
import axios, { AxiosResponse } from "axios";
import { ClientError, default as GraphQLRequest } from "graphql-request";
import * as schema from "../../shared/schema/schema";
import { withValidator } from "../../shared/schema/validate-message";
import assert from "../../shared/utils/assert";
import { Flatten, NeverOnEmpty, OnNever } from "../../shared/utils/types";
import { auth } from "../auth";
import { reviveGraphQLResponse } from "./graphql";
import { env } from "../../env";

const endpointPrefix = "/agencies/:agencyId/agency_members/:agencyMemberId";
type EndpointPrefix = typeof endpointPrefix;

export type Messages = schema.components["schemas"];

type InferPathByMethod = {
  [method in "get" | "post" | "put" | "patch" | "delete"]: {
    [key in keyof schema.paths]: schema.paths[key] extends { [k in method]: unknown } ? key : never;
  }[keyof schema.paths];
};

type MethodEndpoints = {
  get: InferPathByMethod["get"];
  post: InferPathByMethod["post"];
  put: InferPathByMethod["put"];
  patch: InferPathByMethod["patch"];
  delete: InferPathByMethod["delete"];
};

type InferBodyOfPath<T> = T extends {
  requestBody: { content: { "application/json": infer U } };
}
  ? U
  : never;

type InferBodyOf<
  $Method extends keyof MethodEndpoints,
  $Endpoint extends MethodEndpoints[$Method]
> = $Method extends "post"
  ? schema.paths[$Endpoint] extends { post: unknown }
    ? InferBodyOfPath<schema.paths[$Endpoint][$Method]>
    : never
  : $Method extends "put"
  ? schema.paths[$Endpoint] extends { put: unknown }
    ? InferBodyOfPath<schema.paths[$Endpoint][$Method]>
    : never
  : $Method extends "patch"
  ? schema.paths[$Endpoint] extends { patch: unknown }
    ? InferBodyOfPath<schema.paths[$Endpoint][$Method]>
    : never
  : $Method extends "delete"
  ? schema.paths[$Endpoint] extends { delete: unknown }
    ? InferBodyOfPath<schema.paths[$Endpoint][$Method]>
    : never
  : never;

type InferPathParamsOf<
  $Method extends keyof MethodEndpoints,
  $Endpoint extends MethodEndpoints[$Method]
> = schema.paths[$Endpoint] extends { [method in $Method]: { parameters: { path: infer U } } }
  ? Flatten<NeverOnEmpty<Omit<U, "agencyId" | "agencyMemberId">>>
  : never;

type InferQueryParamsOf<
  $Method extends keyof MethodEndpoints,
  $Endpoint extends MethodEndpoints[$Method]
> = schema.paths[$Endpoint] extends { [method in $Method]: { parameters: { query: infer U } } }
  ? Flatten<U>
  : never;

type InferResponseOfPath<T> = T extends {
  responses: {
    200: { content: { "application/json": infer U } };
  };
}
  ? U
  : never;

export type InferResponseOf<
  $Method extends keyof MethodEndpoints,
  $Endpoint extends MethodEndpoints[$Method]
> = $Method extends "get"
  ? schema.paths[$Endpoint] extends { get: unknown }
    ? InferResponseOfPath<schema.paths[$Endpoint][$Method]>
    : never
  : $Method extends "post"
  ? schema.paths[$Endpoint] extends { post: unknown }
    ? InferResponseOfPath<schema.paths[$Endpoint][$Method]>
    : never
  : $Method extends "put"
  ? schema.paths[$Endpoint] extends { put: unknown }
    ? InferResponseOfPath<schema.paths[$Endpoint][$Method]>
    : never
  : $Method extends "patch"
  ? schema.paths[$Endpoint] extends { patch: unknown }
    ? InferResponseOfPath<schema.paths[$Endpoint][$Method]>
    : never
  : $Method extends "delete"
  ? schema.paths[$Endpoint] extends { delete: unknown }
    ? InferResponseOfPath<schema.paths[$Endpoint][$Method]>
    : never
  : never;

type ShortenEndpointPath<T extends string> = T extends `${EndpointPrefix}/${infer U}`
  ? `./${U}`
  : T;

type UnshortenEndpointPath<T extends string> = T extends `./${infer U}`
  ? `${EndpointPrefix}/${U}`
  : T;

type ConditionalRequiredPropery<Type, Key extends keyof Type> = [Type[Key]] extends [undefined]
  ? { [k in Key]?: never }
  : { [k in Key]: Type[Key] };

type ConditionalRestProperties<Type extends { body: unknown; path: unknown; query: unknown }> =
  ConditionalRequiredPropery<Type, "body"> &
    ConditionalRequiredPropery<Type, "path"> &
    ConditionalRequiredPropery<Type, "query">;

export type EndpointOf<$Method extends keyof MethodEndpoints> = ShortenEndpointPath<
  MethodEndpoints[$Method]
>;

export type ResponseOf<
  $Method extends keyof MethodEndpoints,
  $Endpoint extends ShortenEndpointPath<MethodEndpoints[$Method]>
> = UnshortenEndpointPath<$Endpoint> extends MethodEndpoints[$Method]
  ? InferResponseOf<$Method, UnshortenEndpointPath<$Endpoint>>
  : never;

export type QueryParamsOf<
  $Method extends keyof MethodEndpoints,
  $Endpoint extends ShortenEndpointPath<MethodEndpoints[$Method]>
> = UnshortenEndpointPath<$Endpoint> extends MethodEndpoints[$Method]
  ? InferQueryParamsOf<$Method, UnshortenEndpointPath<$Endpoint>>
  : never;

export type BodyOf<
  $Method extends keyof MethodEndpoints,
  $Endpoint extends ShortenEndpointPath<MethodEndpoints[$Method]>
> = UnshortenEndpointPath<$Endpoint> extends MethodEndpoints[$Method]
  ? InferBodyOf<$Method, UnshortenEndpointPath<$Endpoint>>
  : never;

const publicEndpoints = new Set<keyof schema.paths>(["/auth/new_login"]);

function getEndpointFullPath<$Endpoint extends ShortenEndpointPath<keyof schema.paths>>(
  endpoint: $Endpoint
): UnshortenEndpointPath<$Endpoint> {
  return (
    endpoint.startsWith("./") ? endpoint.replace("./", `${endpointPrefix}/`) : endpoint
  ) as UnshortenEndpointPath<$Endpoint>;
}

export type API = ReturnType<typeof createApi>;

export function createApi() {
  let $refetchTokenPromise: Promise<void> | null = null;

  function isAuthorized(endpoint: keyof schema.paths) {
    return auth.getAuthInfo() !== null || publicEndpoints.has(endpoint);
  }

  async function get<
    $Endpoint extends ShortenEndpointPath<MethodEndpoints["get"]>,
    $Params extends {
      path: OnNever<InferPathParamsOf<"get", UnshortenEndpointPath<$Endpoint>>, undefined>;
      query: OnNever<InferQueryParamsOf<"get", UnshortenEndpointPath<$Endpoint>>, undefined>;
    }
  >(
    endpoint: $Endpoint,
    options: (ConditionalRequiredPropery<$Params, "path"> &
      ConditionalRequiredPropery<$Params, "query">) & {
      refreshTokenOnFailure?: boolean;
    }
  ): Promise<InferResponseOf<"get", UnshortenEndpointPath<$Endpoint>>> {
    const fullEndpoint = getEndpointFullPath(endpoint);

    if (!isAuthorized(fullEndpoint)) {
      throw new Error("Unauthorized");
    }

    const authState = auth.getAuthOrFail();
    const currentAuthToken = authState.tokens.authToken;

    const url = parseEndpointPath({
      path: fullEndpoint,
      params: {
        path: {
          agencyId: authState.data.agency.id,
          agencyMemberId: authState.data.agencyMember.id,
          ...(options.path ?? {}),
        },
        query: options.query as Record<string, unknown> | undefined,
      },
    });

    try {
      const { data } = await axios({
        method: "GET",
        baseURL: env.API_URL,
        url: url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${authState.tokens.authToken}`,
        },
      });

      return await withValidator({
        data: data,
        method: "GET",
        url: fullEndpoint,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401 && (options.refreshTokenOnFailure ?? true)) {
          if (currentAuthToken === auth.getTokens()?.authToken) {
            await refetchToken();
          }

          console.log(`[api] retrying ${url}`);

          return get(endpoint, {
            ...options,
            refreshTokenOnFailure: false,
          });
        }
      }

      throw error;
    }
  }

  async function post<
    $Endpoint extends ShortenEndpointPath<MethodEndpoints["post"]>,
    $Params extends {
      body: OnNever<InferBodyOf<"post", UnshortenEndpointPath<$Endpoint>>, undefined>;
      path: OnNever<InferPathParamsOf<"post", UnshortenEndpointPath<$Endpoint>>, undefined>;
      query: OnNever<InferQueryParamsOf<"post", UnshortenEndpointPath<$Endpoint>>, undefined>;
    }
  >(
    endpoint: $Endpoint,
    options: ConditionalRestProperties<$Params> & {
      refreshTokenOnFailure?: boolean;
    }
  ): Promise<InferResponseOf<"post", UnshortenEndpointPath<$Endpoint>>> {
    const fullEndpoint = getEndpointFullPath(endpoint);

    if (!isAuthorized(fullEndpoint)) {
      throw new Error("Unauthorized");
    }

    const authState = auth.getAuthOrFail();

    const url = parseEndpointPath({
      path: fullEndpoint,
      params: {
        path: {
          agencyId: authState.data.agency.id,
          agencyMemberId: authState.data.agencyMember.id,
          ...(options.path ?? {}),
        },
        query: options.query as Record<string, unknown> | undefined,
      },
    });

    try {
      const { data } = await axios({
        method: "POST",
        baseURL: env.API_URL,
        url: url,
        data: JSON.stringify(options.body),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${authState.tokens.authToken}`,
        },
      });

      return await withValidator({
        data: data,
        method: "POST",
        url: fullEndpoint,
      });
    } catch (error) {
      if (axios.isAxiosError(error) && endpoint !== "/auth/new_login") {
        if (error.response?.status === 401 && (options.refreshTokenOnFailure ?? true)) {
          return refetchToken().then(() =>
            post(endpoint, {
              ...options,
              refreshTokenOnFailure: false,
            })
          );
        }
      }

      throw error;
    }
  }

  async function put<
    $Endpoint extends ShortenEndpointPath<MethodEndpoints["put"]>,
    $Params extends {
      body: OnNever<InferBodyOf<"put", UnshortenEndpointPath<$Endpoint>>, undefined>;
      path: OnNever<InferPathParamsOf<"put", UnshortenEndpointPath<$Endpoint>>, undefined>;
      query: OnNever<InferQueryParamsOf<"put", UnshortenEndpointPath<$Endpoint>>, undefined>;
    }
  >(
    endpoint: $Endpoint,
    options: ConditionalRestProperties<$Params> & {
      refreshTokenOnFailure?: boolean;
    }
  ): Promise<InferResponseOf<"put", UnshortenEndpointPath<$Endpoint>>> {
    const fullEndpoint = getEndpointFullPath(endpoint);

    if (!isAuthorized(fullEndpoint)) {
      throw new Error("Unauthorized");
    }

    const authState = auth.getAuthOrFail();

    const url = parseEndpointPath({
      path: fullEndpoint,
      params: {
        path: {
          agencyId: authState.data.agency.id,
          agencyMemberId: authState.data.agencyMember.id,
          ...(options.path ?? {}),
        },
        query: options.query as Record<string, unknown> | undefined,
      },
    });

    try {
      const { data } = await axios({
        method: "PUT",
        baseURL: env.API_URL,
        url: url,
        data: JSON.stringify(options.body),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${authState.tokens.authToken}`,
        },
      });

      return await withValidator({
        data: data,
        method: "PUT",
        url: fullEndpoint,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401 && (options.refreshTokenOnFailure ?? true)) {
          return refetchToken().then(() =>
            put(endpoint, {
              ...options,
              refreshTokenOnFailure: false,
            })
          );
        }
      }

      throw error;
    }
  }

  async function patch<
    $Endpoint extends ShortenEndpointPath<MethodEndpoints["patch"]>,
    $Params extends {
      body: OnNever<InferBodyOf<"patch", UnshortenEndpointPath<$Endpoint>>, undefined>;
      path: OnNever<InferPathParamsOf<"patch", UnshortenEndpointPath<$Endpoint>>, undefined>;
      query: OnNever<InferQueryParamsOf<"patch", UnshortenEndpointPath<$Endpoint>>, undefined>;
    }
  >(
    endpoint: $Endpoint,
    options: ConditionalRestProperties<$Params> & {
      refreshTokenOnFailure?: boolean;
    }
  ): Promise<InferResponseOf<"patch", UnshortenEndpointPath<$Endpoint>>> {
    const fullEndpoint = getEndpointFullPath(endpoint);

    if (!isAuthorized(fullEndpoint)) {
      throw new Error("Unauthorized");
    }

    const authState = auth.getAuthOrFail();

    const url = parseEndpointPath({
      path: fullEndpoint,
      params: {
        path: {
          agencyId: authState.data.agency.id,
          agencyMemberId: authState.data.agencyMember.id,
          ...(options.path ?? {}),
        },
        query: options.query as Record<string, unknown> | undefined,
      },
    });

    try {
      const { data } = await axios({
        method: "PATCH",
        baseURL: env.API_URL,
        url: url,
        data: JSON.stringify(options.body),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${authState.tokens.authToken}`,
        },
      });

      return await withValidator({
        data: data,
        method: "PATCH",
        url: fullEndpoint,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401 && (options.refreshTokenOnFailure ?? true)) {
          return refetchToken().then(() =>
            patch(endpoint, {
              ...options,
              refreshTokenOnFailure: false,
            })
          );
        }
      }

      throw error;
    }
  }

  async function $delete<
    $Endpoint extends ShortenEndpointPath<MethodEndpoints["delete"]>,
    $Params extends {
      body: OnNever<InferBodyOf<"delete", UnshortenEndpointPath<$Endpoint>>, undefined>;
      path: OnNever<InferPathParamsOf<"delete", UnshortenEndpointPath<$Endpoint>>, undefined>;
      query: OnNever<InferQueryParamsOf<"delete", UnshortenEndpointPath<$Endpoint>>, undefined>;
    }
  >(
    endpoint: $Endpoint,
    options: ConditionalRestProperties<$Params> & {
      refreshTokenOnFailure?: boolean;
    }
  ): Promise<InferResponseOf<"delete", UnshortenEndpointPath<$Endpoint>>> {
    const fullEndpoint = getEndpointFullPath(endpoint);

    if (!isAuthorized(fullEndpoint)) {
      throw new Error("Unauthorized");
    }

    const authState = auth.getAuthOrFail();

    const url = parseEndpointPath({
      path: fullEndpoint,
      params: {
        path: {
          agencyId: authState.data.agency.id,
          agencyMemberId: authState.data.agencyMember.id,
          ...(options.path ?? {}),
        },
        query: options.query as Record<string, unknown> | undefined,
      },
    });

    try {
      const { data } = await axios({
        method: "DELETE",
        baseURL: env.API_URL,
        url: url,
        data: JSON.stringify(options.body),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${authState.tokens.authToken}`,
        },
      });

      return await withValidator({
        data: data,
        method: "DELETE",
        url: fullEndpoint,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401 && (options.refreshTokenOnFailure ?? true)) {
          return refetchToken().then(() =>
            $delete(endpoint, {
              ...options,
              refreshTokenOnFailure: false,
            })
          );
        }
      }

      throw error;
    }
  }

  async function graphql<
    $Data extends Record<string, unknown>,
    $Variables extends Record<string, unknown>
  >(
    query: TypedDocumentNode<$Data, $Variables>,
    options: {
      variables: $Variables | undefined;
      headers?: Record<string, string | undefined>;
      refreshTokenOnFailure?: boolean;
    }
  ): Promise<$Data> {
    const authState = auth.getAuthOrFail();
    const currentAuthToken = authState.tokens.authToken;

    const url = parseEndpointPath({
      path: `graphql/agency_member/:agencyMemberId`,
      params: {
        path: {
          agencyMemberId: authState.data.agencyMember.id,
        },
      },
    });
    try {
      const data = await GraphQLRequest<$Data>({
        url: `${env.API_URL}${url}`,
        document: query,
        requestHeaders: {
          Authorization: `Token ${authState.tokens.authToken}`,
          Accept: "application/json",
          ...options.headers,
        },
        variables: options.variables,
      });

      return reviveGraphQLResponse(query, data);
    } catch (error) {
      if (error instanceof ClientError) {
        if (error.response.status === 401 && (options.refreshTokenOnFailure ?? true)) {
          if (currentAuthToken === auth.getTokens()?.authToken) {
            await refetchToken();
          }

          console.log(`[api] retrying ${url}`);

          return graphql(query, {
            ...options,
            refreshTokenOnFailure: false,
          });
        }
      }

      throw error;
    }
  }

  async function anyRequest<TResponse>(
    method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE",
    endpoint: string,
    options: {
      body?: Record<PropertyKey, unknown>;
      path?: Record<PropertyKey, unknown>;
      query?: Record<PropertyKey, unknown>;
      refreshTokenOnFailure?: boolean;
      baseUrl?: string;
    }
  ): Promise<TResponse> {
    const authState = auth.getAuthOrFail();

    const url = parseEndpointPath({
      path: endpoint,
      params: {
        path: {
          agencyId: authState.data.agency.id,
          agencyMemberId: authState.data.agencyMember.id,
          ...(options.path ?? {}),
        },
        query: options.query,
      },
    });

    try {
      const { data } = await axios({
        method: method,
        baseURL: options.baseUrl ?? env.API_URL,
        url: url,
        data: JSON.stringify(options.body),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${authState.tokens.authToken}`,
        },
      });

      return data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401 && (options.refreshTokenOnFailure ?? true)) {
          return refetchToken().then(() => {
            return anyRequest(method, endpoint, { ...options, refreshTokenOnFailure: false });
          });
        }
      }

      throw error;
    }
  }

  async function refetchToken() {
    if ($refetchTokenPromise !== null) {
      console.log("[api] token refetch is already in progress");
      return $refetchTokenPromise;
    }

    console.log("[api] token refetch is starting");

    const tokens = auth.getTokens();
    assert(tokens !== null, "Missing auth tokens");

    $refetchTokenPromise = new Promise<void>((resolve, reject) => {
      axios({
        method: "POST",
        baseURL: env.API_URL,
        url: "/auth/token",
        headers: {
          "X-MedFlyt-grant-type": tokens.refreshToken,
        },
      })
        .then((response: AxiosResponse<InferResponseOf<"post", "/auth/token">>) => {
          auth.setFromRefetch(response.data);
          console.log("[api] token refetch is done");
          $refetchTokenPromise = null;
          resolve();
        })
        .catch(() => {
          console.log("[api] token refetch failed");
          auth.logout();
          reject();
        });
    });

    return $refetchTokenPromise;
  }

  return { get, post, put, patch, delete: $delete, graphql, anyRequest };
}
export function parseEndpointPath(params: {
  path: string;
  params: {
    path?: Record<string, unknown>;
    query?: Record<string, unknown>;
  };
}) {
  const {
    path,
    params: { path: pathParams, query },
  } = params;

  let url = path.replace(/:([^/]+)/g, (_match, key) => {
    if (pathParams === undefined) {
      throw new Error(`Missing path param ${key} in ${path}`);
    }

    const value: unknown = pathParams[key];

    if (value === undefined) {
      throw new Error(`Missing path param: ${key}`);
    }

    if (value === null) {
      return "null";
    }

    return `${value}`;
  });

  if (query !== null && query !== undefined && Object.keys(query).length > 0) {
    url = `${url}?${serializeQuery(query)}`;
  }

  return url;
}

function serializeQuery<T>(params: T) {
  const parsed = JSON.parse(JSON.stringify(params));

  const queries: string[] = [];

  for (const [key, value] of Object.entries(parsed)) {
    switch (true) {
      case value === undefined:
        break;
      case Array.isArray(value):
        for (const item of value) {
          queries.push(`${key}[]=${encodeURIComponent(item)}`);
        }
        break;
      case value !== null && typeof value === "object":
        queries.push(`${key}=${encodeURIComponent(JSON.stringify(value))}`);
        break;
      default:
        queries.push(`${key}=${encodeURIComponent(value as string | number)}`);
    }
  }

  return queries.join("&");
}
