import React from "react";
import { auth } from "../../../core/auth";
import CallCenterListeners from "../../../modules/call-center/components/CallCenterListeners";
import CommCenterSocketsListener from "../../../modules/communication/components/CommCenterSocketsListener";
import CommCenterTicketPopupListener from "../../../modules/communication/components/CommCenterTicketPopupListener";
import VisitAssignmentRoute from "../../../modules/visit/pages/assignments/VisitAssignmentRoute";
import EditNoteModalListener from "./EditNoteModalListener";
import EntityCommunicationsListener from "./EntityCommunicationsListener";
import GlobalWorkflowRunner from "./GlobalWorkflowModal";
import LastRouteStateDispatcher from "./LastRouteStateDispatcher";
import NewTicketPopupListener from "./NewTicketPopupListener";
import SendOutboundSMSResponseListener from "./SendOutboundSMSResponseListener";
import SmsSenderListener from "./SmsSenderListener";
import { VisitAssignmentListeners } from "./VisitAssignmentListeners";

/**
 * This component is global in the webapp and is being imported from content.html
 */
export default function ReactHost() {
  const authState = auth.getAuthState();

  React.useEffect(() => {
    console.log("[react-host] init");
  }, []);

  if (authState.isMedflytAgencyMember !== true) {
    return <></>;
  }

  return (
    <>
      <LastRouteStateDispatcher />
      <GlobalWorkflowRunner />
      <CommCenterSocketsListener />
      <CommCenterTicketPopupListener />
      <SmsSenderListener />
      <EditNoteModalListener />
      <NewTicketPopupListener />
      <CallCenterListeners />
      <EntityCommunicationsListener />
      <SendOutboundSMSResponseListener />
      <VisitAssignmentListeners />

      {/* absolute routes */}
      <VisitAssignmentRoute />
    </>
  );
}
