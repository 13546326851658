import { Messages } from "../../../core/api";
import { CaregiverId, CommCenterTeamId, PatientId } from "../../schema/schema";

export interface PhoneNumber {
  label: string;
  value: string;
}

export interface SelectedCommCenterTeam {
  value: CommCenterTeamId;
  label: string;
}

export interface PortalLink {
  label: string;
  value: string;
  redirectUrl: Messages["PatientPortalRedirectUrl"];
}

export interface RegularMessage {
  label: string;
  value: string;
}

export enum DefaultSmsMessageFields {
  FIRST_NAME = "{FIRST_NAME}",
  AGENCY_MEMBER = "{AGENCY_MEMBER}",
}

export interface SendSmsBody {
  phoneNumber: string;
  message: string;
  teamId?: CommCenterTeamId;
}

export type SendPortalLinkSmsBody = SendSmsBody & {
  redirectUrl: Messages["PatientPortalRedirectUrl"];
};

export type MessageTemplate = PortalLink | RegularMessage;

export type QueriedEntity = {
  firstName: string;
  phoneNumbers: PhoneNumber[];
};

export type SmsSenderProps = PatientSmsSenderProps | CaregiverSmsSenderProps;

interface DefaultSmsSenderProps {
  predefinedPhoneNumber?: string;
  predefinedMessageTemplate?: MessageTemplate;
}

export type PatientSmsSenderProps = DefaultSmsSenderProps & {
  entity: "Patient";
  patientId: PatientId;
};

export type CaregiverSmsSenderProps = DefaultSmsSenderProps & {
  entity: "Caregiver";
  caregiverId: CaregiverId;
};

export type NewSmsTicketRequestBody = {
  caregiverId: CaregiverId | null;
  patientId: PatientId | null;
  message: string;
  topic: "Patient" | "Caregiver";
  phoneNumber?: string | null;
  teamId?: CommCenterTeamId;
};
