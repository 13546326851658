import { Messages } from "../../../core/api";
import { DefaultSmsMessageFields } from "./sms-sender.types";

export const replaceMessageTemplatePlaceholders = (
  messageTemplate: string,
  values: { firstName: string; agencyMember: Messages["AgencyMember"] | undefined }
) => {
  return messageTemplate
    .replace(DefaultSmsMessageFields.FIRST_NAME, values.firstName)
    .replace(DefaultSmsMessageFields.AGENCY_MEMBER, values.agencyMember?.firstName ?? "");
};
