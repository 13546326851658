import { Box, Flex, Menu, MenuButton, MenuItem, MenuList, Text } from "@chakra-ui/react";
import { SearchEntity } from "../../modules/communication/components/DialerTabs";
import Dot02SIcon from "../icons/Dot02SIcon";
import { getFullName } from "../utils/get-full-name";
import { phoneFormatter } from "../utils/phone-formatter";
import EntityCard, { CaregiverEntity, PatientEntity } from "./EntityCard";

const EntityPreview = ({
  isLoading,
  entity,
  onClickDial,
}: {
  isLoading: boolean;
  entity: SearchEntity;
  onClickDial: (val: string) => void;
}) => {
  const formattedEntity = (() => {
    switch (entity.type) {
      case "Patient":
        return {
          ...entity,
          contactDetails: null,
          fullName: getFullName(entity),
        } satisfies PatientEntity;
      case "Caregiver":
        return {
          ...entity,
          fullName: getFullName(entity),
        } satisfies CaregiverEntity;
    }
  })();
  return (
    <Menu isOpen={isLoading ? false : undefined}>
      <MenuButton as={Box} borderBottom="1px" borderColor="gray.100" pb={2} textAlign="start">
        <EntityCard
          boxProps={{ opacity: isLoading ? 0.5 : 1, cursor: isLoading ? undefined : "pointer" }}
          entity={formattedEntity}
        />
      </MenuButton>
      <MenuList>
        {entity.phoneNumbersExtended.map((phoneNumber) => (
          <MenuItem
            key={phoneNumber.phoneNumber}
            as={Flex}
            cursor="pointer"
            onClick={() => onClickDial(phoneNumber.phoneNumber)}
          >
            {phoneFormatter.formatNationalIfValid(phoneNumber.phoneNumber)}
            <Dot02SIcon />
            <Text>{phoneNumber.type}</Text>
          </MenuItem>
        ))}
        {entity.type === "Patient" &&
          entity.familyInfo.flatMap((familyMember) => [
            <MenuItem
              key={`${familyMember.firstName}-home`}
              as={Flex}
              cursor="pointer"
              onClick={() => onClickDial(familyMember.homePhoneNumber)}
            >
              {phoneFormatter.formatNationalIfValid(familyMember.homePhoneNumber)}
              <Dot02SIcon />
              <Text>
                {familyMember.firstName} {familyMember.lastName}
              </Text>
              <Dot02SIcon />
              <Text>Home</Text>
            </MenuItem>,
            <MenuItem
              key={`${familyMember.firstName}-mobile`}
              as={Flex}
              cursor="pointer"
              onClick={() => onClickDial(familyMember.mobilePhoneNumber)}
            >
              {phoneFormatter.formatNationalIfValid(familyMember.mobilePhoneNumber)}
              <Dot02SIcon />
              <Text>
                {familyMember.firstName} {familyMember.lastName}
              </Text>
              <Dot02SIcon />
              <Text>Mobile</Text>
            </MenuItem>,
          ])}
      </MenuList>
    </Menu>
  );
};

export default EntityPreview;
