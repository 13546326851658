import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { createApi } from "./api-utils";
import { createMutations } from "./mutations";
import { createQueries } from "./queries";

export const ApiContext = React.createContext<{
  api: ReturnType<typeof createApi>;
  mutations: ReturnType<typeof createMutations>;
  queries: ReturnType<typeof createQueries>;
}>(null as any);

const api = createApi();

export const ApiProvider = (props: { children: React.ReactNode }) => {
  const queryClient = useQueryClient();
  const queries = React.useMemo(() => createQueries({ api, queryClient }), [queryClient]);
  const mutations = createMutations({ api });

  return (
    <ApiContext.Provider value={{ api, queries, mutations }}>{props.children}</ApiContext.Provider>
  );
};
