import {
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Text,
  UseDisclosureReturn,
  useToast,
} from "@chakra-ui/react";
import useApi from "../../../../shared/hooks/useApi";
import { PatientId } from "../../../../shared/schema/schema";
import { useMutation, useQuery } from "@tanstack/react-query";
import React from "react";
import SingleDateTimePicker from "../../../../shared/components/DatePicker/SingleDateTimePicker";
import { LocalDateTime } from "@js-joda/core";
import { formatErrorResponse } from "../../../../shared/utils/format-response-error";

const ManuallySetNextCallDateAfterCallModal = (props: {
  disclosure: UseDisclosureReturn;
  patientId: PatientId;
}) => {
  const { queries, api } = useApi();
  const patientDetails = useQuery(queries.patient.get(props.patientId));
  const [dateTime, setDateTime] = React.useState<LocalDateTime | null>(null);
  const [error, setError] = React.useState<string | null>(null);
  const toast = useToast();

  const { mutate: updateNextCallDate, isPending } = useMutation({
    mutationFn: async (params: { nextCallDate: LocalDateTime }) => {
      await api.put("./patient_intake/:patientId/next_call_date", {
        path: { patientId: props.patientId },
        body: {
          nextCallDate: params.nextCallDate,
        },
      });
    },
    onSuccess: () => {
      toast({
        title: "Call scheduled",
        status: "success",
        position: "top-right",
      });
    },
    onError: (error) => {
      toast({
        title: "Error scheduling call date",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
    onSettled: props.disclosure.onClose,
  });

  const handleClickSubmit = () => {
    if (dateTime === null) {
      setError("Please select a date and time");
      return;
    }
    updateNextCallDate({ nextCallDate: dateTime });
  };

  const header = ((): React.ReactNode => {
    if (patientDetails.isSuccess) {
      return "Set Next Call Date";
    }

    if (patientDetails.isError) {
      return "Something Went Wrong";
    }

    return <Skeleton />;
  })();

  const body = ((): React.ReactNode => {
    if (patientDetails.isError) {
      return "Error loading patient details";
    }

    if (patientDetails.isSuccess) {
      return (
        <Flex flexDir="column" gap={2} w="full">
          <Heading size="md">
            No Next Call Date For Patient {patientDetails.data.firstName}{" "}
            {patientDetails.data.lastName}
          </Heading>
          <Text>This patient has no call scheduled. Would you like to reschedule one now?</Text>
          <Flex flexDir="column" gap={1} w="full">
            <SingleDateTimePicker
              minDate={LocalDateTime.now()}
              selected={dateTime}
              onChange={(date) => {
                setDateTime(date);
                setError(null);
              }}
            />
            {error && <Text color="red.500">{error}</Text>}
          </Flex>
        </Flex>
      );
    }

    return <Skeleton height="100px" width="full" />;
  })();

  return (
    <Modal {...props.disclosure} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{header}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{body}</ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" isDisabled={isPending} mr={3} onClick={handleClickSubmit}>
            Schedule & Close
          </Button>
          <Button colorScheme="red" variant="outline" onClick={props.disclosure.onClose}>
            Close Without Schedule
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ManuallySetNextCallDateAfterCallModal;
