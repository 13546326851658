import { useDisclosure, useToast } from "@chakra-ui/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useRouter } from "@uirouter/react";
import React from "react";
import { Messages } from "../../../core/api";
import { tabId } from "../../../shared/consts/tab-id";
import useApi from "../../../shared/hooks/useApi";
import useAuthData from "../../../shared/hooks/useAuthInfo";
import useMultipleSocketEvent from "../../../shared/hooks/useMultipleSocketEvent";
import { useReactBroadcastChannelEvent } from "../../../shared/hooks/useReactBroadcastChannel";
import useSocketEvent from "../../../shared/hooks/useSocketEvent";
import assert from "../../../shared/utils/assert";
import AfterCallCallTypeModal from "../../communication/components/AfterCallCallTypeModal/AfterCallCallTypeModal";
import InviteToCallModal from "../../communication/components/CallTicket/InviteToCallModal";
import TicketEntitySelectModal from "../../communication/components/TicketEntitySelectModal/TicketEntitySelectModal";
import ScheduleNextCallDateModal from "../../patientIntake/components/ScheduleNextCallDateModal";
import {
  getIsTelephonyInboundCallInvitation,
  getTelephonyCallParticipantTypeAndName,
  isIntakeCall,
  isOutboundIntakeCall,
  shouldRing,
} from "../call-center.utils";
import useActivePhoneProviderClientTab from "../hooks/useActivePhoneProviderClientTab";
import useCallCenter from "../hooks/useCallCenter";
import useCallCenterBeforeUnloadListener from "../hooks/useCallCenterBeforeUnloadListener";
import useCallState from "../hooks/useCallState";
import usePhoneProvider from "../hooks/usePhoneProvider";
import usePhoneProviderListeners from "../hooks/usePhoneProviderListeners";
import useScheduleNextCall from "../hooks/useScheduleNextCall";
import ActiveCallBanner from "./ActiveCallBanner";
import ActiveInboundCallWrapper from "./ActiveInboundCallWrapper";
import { AlreadyInACallOnAnotherTabDialog } from "./AlreadyInACallOnAnotherTabDialog";
import InboundCallOverlay from "./InboundCallOverlay";
import PhoneNumberDialerPopupListener from "./PhoneNumberDialerPopupListener";
import ManuallySetNextCallDateAfterCallModal from "../../communication/components/ManuallySetNextCallDateAfterCallModal/ManuallySetNextCallDateAfterCallModal";

export default function CallCenterListeners() {
  useCallCenterBeforeUnloadListener();

  const queryClient = useQueryClient();
  const toast = useToast();
  const callCenter = useCallCenter();
  const afterCallEntityModalDisclosure = useDisclosure({
    isOpen: callCenter.state.callSummaryTicketId !== null,
    onClose: callCenter.closeSummary,
  });
  const afterCallCallTypeModalDisclosure = useDisclosure({
    isOpen: callCenter.state.afterCallCallTypeData !== null,
    onClose: () => callCenter.setAfterCallCallTypeData(null),
  });
  const manualSetNextCallDateModalDisclosure = useDisclosure({
    isOpen: callCenter.state.setManuallyNextCallDateData !== null,
    onClose: () => callCenter.setManuallyNextCallDateData(null),
  });

  const { stateService } = useRouter();

  const activeClientTab = useActivePhoneProviderClientTab();
  const phoneProvider = usePhoneProvider();
  const [callState, dispatchCallState] = useCallState();
  const { queries } = useApi();
  const scheduleNextCall = useScheduleNextCall();
  const sipEndpointsQuery = useQuery(queries.telephony.endpoints());

  React.useEffect(() => {
    if (sipEndpointsQuery.data !== undefined && callState.status === "Loading") {
      dispatchCallState({ type: "Ready" });
    }
  }, [dispatchCallState, sipEndpointsQuery.data, callState.status]);

  React.useEffect(() => {
    const callback = () => {
      if (activeClientTab.isTabActive) {
        console.log("[setActiveCallId -> null] beforeunload", "data before:", {
          tabId: tabId,
          activeTabId: activeClientTab.id,
          activeCallId: activeClientTab.activeCallId,
        });

        activeClientTab.setActiveTabId(null);
        activeClientTab.setActiveCallId(null);
      }
    };

    window.addEventListener("beforeunload", callback);

    return () => {
      window.removeEventListener("beforeunload", callback);
    };
  }, [activeClientTab]);

  usePhoneProviderListeners({
    onMediaConnected: (callSession) => {
      console.log(`[setActiveCallId -> ${callSession.callUUID}] onMediaConnected`, "data before:", {
        tabId: tabId,
        activeTabId: activeClientTab.id,
        activeCallId: activeClientTab.activeCallId,
      });

      activeClientTab.setActiveCallId(callSession.callUUID);
    },
    onCallTerminated: () => {
      console.log("[setActiveCallId -> null] onCallTerminated", "data before:", {
        tabId: tabId,
        activeTabId: activeClientTab.id,
        activeCallId: activeClientTab.activeCallId,
      });

      activeClientTab.setActiveCallId(null);
    },
  });

  useReactBroadcastChannelEvent({
    eventName: "PHONE_CLIENT:LOGOUT",
    onEvent: () => {
      console.log("[plivo client] Received logout event");
      activeClientTab.isTabActive && phoneProvider.logout();
    },
  });

  useReactBroadcastChannelEvent({
    eventName: "CALL_CENTER:DECLINE",
    onEvent: ({ callId }) => {
      if (callCenter.state.status === "Ringing" && callCenter.state.currentCall.id === callId) {
        callCenter.cancelRinging();
      }
    },
  });

  useReactBroadcastChannelEvent({
    eventName: "CALL_CENTER:ANSWER_INBOUND",
    onEvent: ({ call }) => {
      if (
        callCenter.state.status === "Ringing" &&
        callCenter.state.currentCall.id === call.id &&
        window.document.visibilityState === "hidden"
      ) {
        console.log("[call-center] call answered from another tab. cancelling.", call.id);
        callCenter.cancelRinging();
      }
    },
  });

  useSocketEvent({
    key: "TelephonyInboundCallRinging",
    validate: true,
    onEvent: ({ call }) => {
      if (shouldRing({ status: callCenter.state.status })) {
        callCenter.ring(call);
      }
    },
  });

  useSocketEvent({
    key: "TelephonyPortalCallRequested",
    onEvent: ({ call }) => {
      if (shouldRing({ status: callCenter.state.status })) {
        callCenter.ring(call);
      }
    },
  });

  useMultipleSocketEvent({
    keys: [
      "TelephonyInboundCallRinging",
      "TelephonyInboundCallAnswered",
      "TelephonyCallParticipantCreated",
      "TelephonyCallParticipantUpdated",
      "TelephonyCallConnected",
      "TelephonyCallEnded",
      "TelephonyOutboundCallStarted",
    ],
    validate: true,
    onEvent({ call }) {
      const updater = (prev: { call: Messages["TelephonyCallInfo"] } | undefined) => {
        const prevVersion = prev?.call.version ?? 1;
        const nextVersion = call.version;

        return nextVersion > prevVersion ? { call } : prev;
      };

      queryClient.setQueryData(queries.telephony.call(call.id).queryKey, updater);
    },
  });

  useMultipleSocketEvent({
    keys: ["TelephonyCallParticipantCreated", "TelephonyCallParticipantUpdated"],
    onEvent: ({ call, participant }) => {
      const participantInfo = call.participants.find((x) => x.id === participant.id);

      if (participantInfo === undefined) {
        console.error(`[call-center] participant not found: ${participant.id}`);
        return;
      }

      const isSelfParticipant =
        participantInfo.entity.type === "AgencyMember" &&
        participantInfo.entity.id === agencyMember.id;

      const isInCall =
        callCenter.state.status === "Active" && callCenter.state.currentCall.id === call.id;

      if (!isSelfParticipant && !isInCall) {
        return;
      }

      if (participant.status !== undefined) {
        const description = getParticipantStatusMessage({
          isSelfParticipant: isSelfParticipant,
          participantName: getTelephonyCallParticipantTypeAndName(participantInfo).name,
          participantStatus: participant.status,
          participantRole: participantInfo.role,
        });

        if (description !== null) {
          toast({
            status: "info",
            position: "top",
            variant: "subtle",
            description: description,
          });
        }
      }

      if (isSelfParticipant && isInCall && participant.status === "Removed") {
        if (callCenter.state.status === "Ringing") {
          callCenter.cancelRinging();
        }

        if (callCenter.state.status === "Active") {
          callCenter.removedFromCall();
        }
      }
    },
  });

  function handleMissedCall(call: Messages["TelephonyCallInfo"]) {
    if (callCenter.state.status === "Ringing" && callCenter.state.currentCall.id === call.id) {
      toast({
        position: "top-right",
        status: "warning",
        title: "Missed Call",
        description: "The call was not picked up in time",
      });
    }

    callCenter.cancelRinging();
  }

  function handleCallCanceled(call: Messages["TelephonyCallInfo"]) {
    const latestCall =
      callCenter.state.status === "Active"
        ? callCenter.state.currentCall
        : callCenter.state.lastCall;

    if (latestCall !== null && latestCall.id === call.id) {
      toast({
        position: "top-right",
        status: "warning",
        title: "Call Canceled",
        description: "The call was cancelled by the caller before it was answered",
      });
    }

    callCenter.cancelRinging();
  }

  function handleCallEnded({
    call,
    shouldAskForManualCallResult,
  }: Messages["TelephonyCallEndedWithSuccessEventPayload"]) {
    const latestCall =
      callCenter.state.status === "Active"
        ? callCenter.state.currentCall
        : callCenter.state.lastCall;

    if (latestCall?.id !== call.id) {
      return;
    }

    if (shouldAskForManualCallResult && isOutboundIntakeCall(latestCall)) {
      callCenter.setAfterCallCallTypeData({
        callId: call.id,
        patientId:
          latestCall.callee.entity.type === "Patient"
            ? latestCall.callee.entity.id
            : latestCall.callee.entity.patient.id,
      });
    }

    // Hotfix disable this @Bini please fix it
    // if (shouldAskToManuallyEnterNextCallDate) {
    //   const patientId = (() => {
    //     if (isInboundIntakeCall(latestCall)) {
    //       return latestCall.callerInfo.type === "Patient"
    //         ? latestCall.callerInfo.id
    //         : latestCall.callerInfo.patient.id;
    //     }
    //     if (isOutboundIntakeCall(latestCall)) {
    //       return latestCall.callee.entity.type === "Patient"
    //         ? latestCall.callee.entity.id
    //         : latestCall.callee.entity.patient.id;
    //     }
    //     return null;
    //   })();

    //   if (patientId !== null) {
    //     callCenter.setManuallyNextCallDateData({
    //       patientId: patientId,
    //     });
    //   }
    // }

    // This is turned off for now.
    // const participant = latestCall.participants.find(
    //   (
    //     p
    //   ): p is Messages["TelephonyCallParticipantInfo"] & {
    //     entity: { type: "Patient" } | { type: "PatientContact" };
    //   } => p.entity.type === "Patient" || p.entity.type === "PatientContact"
    // );

    // if (participant !== undefined) {
    //   const patient =
    //     participant.entity.type === "Patient" ? participant.entity : participant.entity.patient;

    //   const isInQ1 =
    //     patient.intakeQueue === "Q1 - High" ||
    //     patient.intakeQueue === "Q1 - Initial" ||
    //     patient.intakeQueue === "Q1 - Low";

    //   if (isInQ1 && patient.status !== "LOST") {
    //     scheduleNextCall.setPatient(patient);
    //   }
    // }
  }

  function handleCallFailed({
    call,
    message,
  }: Messages["TelephonyCallEndedWithFailureEventPayload"]) {
    const latestCall =
      callCenter.state.status === "Active"
        ? callCenter.state.currentCall
        : callCenter.state.lastCall;

    if (latestCall?.id !== call.id) {
      return;
    }

    toast({
      position: "top-right",
      status: "error",
      title: "Call Failed",
      description: message,
    });
  }

  useSocketEvent({
    key: "TelephonyCallEnded",
    validate: true,
    onEvent: (event) => {
      if (
        callCenter.state.status === "Active" &&
        callCenter.state.currentCall?.id === event.call.id
      ) {
        callCenter.hangup();
      }

      switch (event.result) {
        case "Missed":
          handleMissedCall(event.call);
          break;
        case "Failed":
          handleCallFailed(event);
          break;
        case "Canceled":
          handleCallCanceled(event.call);
          handleCallEnded(event);
          break;
        case "Completed":
        case "MovedToSMS":
        case "NoAnswer":
        case "VoiceMail":
          handleCallEnded(event);
          break;
      }
    },
  });

  useSocketEvent({
    key: "TelephonyOutboundCallInitiated",
    validate: true,
    onEvent: ({ call }) => {
      const isTheCaller = call.caller.entity.id === agencyMember.id;
      const hasDialedFromTheTab = callCenter.state.status === "Dialing";
      const shouldReactToEvent = isTheCaller && hasDialedFromTheTab;
      const outboundCall = call;

      if (!shouldReactToEvent) {
        return;
      }

      callCenter.connectOutbound(outboundCall);
      callCenter.openCallTicketPopup(outboundCall);

      if (isIntakeCall(outboundCall)) {
        for (const route of ["app.workflow_task_viewer", "app.workflow_tasks.task"]) {
          if (stateService.includes(route)) {
            return;
          }
        }

        const patientId =
          outboundCall.callee.entity.type === "Patient"
            ? outboundCall.callee.entity.id
            : outboundCall.callee.entity.type === "PatientContact"
            ? outboundCall.callee.entity.patient.id
            : null;
        if (patientId !== null) {
          stateService.go("app.patients.intake-flow", {
            patientId,
          });
        }
      }
    },
  });

  const { agencyMember } = useAuthData();

  useSocketEvent({
    key: "TelephonyInboundCallAnswered",
    onEvent: ({ by }) => {
      if (by.id !== agencyMember.id) {
        callCenter.cancelRinging();
      }
    },
  });

  useSocketEvent({
    key: "TelephonyCallConnected",
    onEvent: ({ call }) => {
      const isParticipantInCall = call.participants.some(
        (participant) =>
          participant.entity.type === "AgencyMember" && participant.entity.id === agencyMember.id
      );

      if (
        !isParticipantInCall &&
        callCenter.state.status === "Ringing" &&
        callCenter.state.currentCall.id === call.id
      ) {
        callCenter.cancelRinging();
      }
    },
  });

  useSocketEvent({
    key: "TelephonyConferenceInvitationReceived",
    validate: true,
    onEvent: ({ invitation }) => {
      assert(invitation.call.provider.callId !== null, "provider call id should be defined");

      callCenter.ring({
        ...invitation.call,
        status: "Ringing",
        provider: {
          callId: invitation.call.provider.callId,
          name: invitation.call.provider.name,
        },
        direction: "Inbound",
        caller: {
          entity: invitation.inviter.entity,
          id: invitation.inviter.id,
          isActiveOnCall: true,
          role: "Caller",
          source: invitation.inviter.source,
          status: "Joined",
        },
        callee: {
          id: invitation.invitee.id,
          entity: invitation.invitee.entity,
          isActiveOnCall: false,
          role: "Callee",
          source: invitation.invitee.source,
          status: "Invited",
        },
        ringingTo: [
          {
            id: invitation.invitee.entity.id,
            firstName: invitation.invitee.entity.firstName,
            lastName: invitation.invitee.entity.lastName,
            photoUrl: invitation.invitee.entity.photoUrl,
          },
        ],
      });
    },
  });

  const handleAnswerInbound = (call: Messages["TelephonyInboundCallInfo"]) => {
    console.debug("[call-center] answering call", call.id);

    return callCenter.answer.mutate({
      call: call,
      intent: getIsTelephonyInboundCallInvitation(call) ? "AnswerInvite" : "AnswerInbound",
    });
  };

  const handlePickPortalCallRequest = (call: Messages["TelephonyPortalRequestCallInfo"]) => {
    return callCenter.pickPortalCallRequest.mutate({
      callId: call.id,
      destination: call.requester.phoneNumber,
    });
  };

  const handleAccept = (
    call: Messages["TelephonyInboundCallInfo"] | Messages["TelephonyPortalRequestCallInfo"]
  ) => {
    switch (call.direction) {
      case "Inbound":
        return handleAnswerInbound(call);
      case "PortalCallRequest":
        return handlePickPortalCallRequest(call);
    }
  };

  return (
    <>
      {callCenter.state.status === "Ringing" && (
        <InboundCallOverlay
          call={callCenter.state.currentCall}
          isLoading={callCenter.answer.isPending || callCenter.pickPortalCallRequest.isPending}
          onAccept={handleAccept}
          onClose={callCenter.cancelRinging}
          onDecline={callCenter.decline}
          onTimeout={callCenter.cancelRinging}
        />
      )}
      {callCenter.state.status === "Active" && (
        <ActiveCallBanner
          call={callCenter.state.currentCall}
          startedAt={callCenter.state.startedAt}
          onClick={callCenter.openCallTicketPopup}
          onClickHangup={callCenter.hangup}
        />
      )}
      {callCenter.state.callSummaryTicketId !== null && (
        <TicketEntitySelectModal
          disclosure={afterCallEntityModalDisclosure}
          ticketId={callCenter.state.callSummaryTicketId}
        />
      )}
      {callCenter.state.afterCallCallTypeData !== null && (
        <AfterCallCallTypeModal
          callId={callCenter.state.afterCallCallTypeData.callId}
          disclosure={afterCallCallTypeModalDisclosure}
          patientId={callCenter.state.afterCallCallTypeData.patientId}
        />
      )}

      {callCenter.state.setManuallyNextCallDateData !== null && (
        <ManuallySetNextCallDateAfterCallModal
          disclosure={manualSetNextCallDateModalDisclosure}
          patientId={callCenter.state.setManuallyNextCallDateData.patientId}
        />
      )}

      <PhoneNumberDialerPopupListener />
      <AlreadyInACallOnAnotherTabDialog
        isOpen={callCenter.alreadyInACallDialog.isOpen}
        onClose={callCenter.alreadyInACallDialog.close}
      />
      <InviteToCallModal />
      {callState.status === "Active" && callState.currentCall.direction === "Inbound" && (
        <ActiveInboundCallWrapper call={callState.currentCall} />
      )}
      <ScheduleNextCallDateModal
        isOpen={scheduleNextCall.isOpen}
        patient={scheduleNextCall.patient}
        onClose={scheduleNextCall.close}
      />
    </>
  );
}

function getParticipantStatusMessage(params: {
  isSelfParticipant: boolean;
  participantName: string;
  participantStatus: Messages["TelephonyCallParticipantStatus"];
  participantRole: Messages["TelephonyCallParticipantRole"];
}) {
  switch (`${params.isSelfParticipant ? "Self" : ""}${params.participantStatus}` as const) {
    case "Answered":
      return `${params.participantName} answered the call`;
    case "BargedIn":
      return `${params.participantName} has barged in`;
    case "Declined":
      return `${params.participantName} declined the call`;
    case "EavesDropped":
      return null;
    case "Hungup":
      return `${params.participantName} hung up`;
    case "Initiated":
      return `${params.participantName} initiated the call`;
    case "Invited":
      return `${params.participantName} invited to the call`;
    case "Joined":
      return `${params.participantName} joined the call`;
    case "Left":
      return `${params.participantName} left the call`;
    case "Removed":
      return `${params.participantName} removed from the call`;
    case "SelfAnswered":
      return `You have answered the call`;
    case "SelfBargedIn":
      return `You have barged in`;
    case "SelfDeclined":
      return `You have declined the call`;
    case "SelfEavesDropped":
      return `You are eavesdropping the call`;
    case "SelfHungup":
      return `You hung up`;
    case "SelfInitiated":
      return null;
    case "SelfInvited":
      return null;
    case "SelfJoined":
      return `You have joined the call`;
    case "SelfLeft":
      return `You have left the call`;
    case "SelfRemoved":
      return `You have been removed from the call`;
  }
}
